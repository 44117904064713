import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';

import { LoginI } from 'src/app/models/login.interface';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    isLogged: boolean;
    loginForm: FormGroup;
    public showPassword: boolean = false;


    constructor(private route: Router, private api: ApiService) {
        this.isLogged = false;
        this.loginForm = new FormGroup({
            user: new FormControl('', [Validators.required]),
            password: new FormControl('',[Validators.required])
        });
    }

    ngOnInit(){
        this.api.show();
    }

    onLogin(form:LoginI){
        if (this.loginForm.valid){
            this.api.login(form).subscribe( data => {
                this.route.navigate(['cargas/cruces']);
            });
        }
    }

    public togglePasswordVisibility(): void {
        this.showPassword = !this.showPassword;
      }

}
