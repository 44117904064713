import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';

export class fila_tabla {
  // id_encomienda: number=0;
  rut: string='';
  nombre: string='';
  apellido: string='';
  telefono: string = '' ;
  email: string='';
}


@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css']
})
export class PersonaComponent implements OnInit {


  formulario_personas: FormGroup;

  

  // encomienda: string='';

  constructor(private api: ApiService, private _http:HttpClient, private fb: FormBuilder, private route: Router) {
    this.formulario_personas = new FormGroup({
        // encomienda: new FormControl('')
        persona: this.fb.array([
          this.fb.group(new fila_tabla())
      ]),
    });
   }

   usuario = sessionStorage.getItem('usuario')

  ngOnInit(): void {
  }

  guardar_formulario(){
    console.log('guarda encomienda');
    this.api.postMultiPersonas(this.formulario_personas.value);
    console.log('encomienda guardada');
  }

  volver(){
    this.route.navigate(['/cargas/cruces']);
  }

  get fila(){
    return this.formulario_personas.get('persona') as FormArray;
  }

  agregar_fila(){
    this.fila.push(this.fb.group(new fila_tabla()));
  }


  borrar_linea(i: number){
    this.fila.removeAt(i);
  }

  salir(){
    this.api.logout();
    this.route.navigate(['login']);
  }


}
