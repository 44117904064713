<mat-card>
    <mat-card-title></mat-card-title>

    <mat-card-content >
        <br><br>
        <div class="mat-elevation-z8">
        <mat-table [dataSource]="encomiendas">

            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef> Ítem </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="item"> {{element.item}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="estado">
                <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="estado"> {{element.estado}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
        </mat-table>
        </div>
    </mat-card-content>
</mat-card>
