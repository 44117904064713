import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'app-manifiesto-carga',
  templateUrl: './manifiesto-carga.component.html',
  styleUrls: ['./manifiesto-carga.component.css']
})
export class ManifiestoCargaComponent implements OnInit {
  id_cruce: number;
  bultos! : any[];
  // columnas : string[] = ['numero','destinatario','identificacion','nombre','total','cantidad','observaciones','lote'];
  columnas : string[] = ['encomienda','observaciones','remitente','residente_entrega','destinatario','residente_recibe','identificacion','nombre','total','cantidad'/*,'lote'*/];
  constructor(private router: Router, private api: ApiService,public dialog: MatDialog, private app: AppComponent) {
      this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
      this.app.setLoading(true);
  }

  ngOnInit(): void {
    this.api.getManifiesto(this.id_cruce).subscribe(
        data => {
            this.bultos = data.data;
            console.log(`bultos : ${JSON.stringify(this.bultos)}`);
            this.app.setLoading(false);

        }
    );
  }


  back(id_cruce : number){
    this.router.navigate(['/cargas/cruces/lista_envios'],{state: { id_cruce: id_cruce }});
  }

}
