import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import Swal from 'sweetalert2';
import { Navigation } from 'selenium-webdriver';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.css'],
  providers: []
})


export class BuscarComponent implements OnInit {

    formulario: FormGroup;

    encomienda: string='';


    constructor(private router: Router,private api: ApiService, private fb: FormBuilder) {
        this.formulario = new FormGroup({
            encomienda: new FormControl('')
        });
    }
    usuario = sessionStorage.getItem('usuario')
    
    ngOnInit(): void {
    }


    // cargar_contenido(){
    //   console.log(this.formulario.value.encomienda)
    //     if (this.formulario.value.encomienda==''){
    //         Swal.fire({
    //             title: 'Atención',
    //             text: 'Indicar Nº Encomienda'
    //         });
    //     }else{
    //         this.router.navigate(['/buscar/comprobantes'],
    //         {state: { encomienda: this.formulario.value.encomienda}});
    //     }

    // }

    cargar_contenido(){

        if (this.formulario.value.encomienda==''){
            Swal.fire({
                title: 'Atención',
                text: 'Indicar Encomienda'
            });
        }
        else if(this.router.url == '/buscar'){
            this.router.navigate(['/buscar/comprobantes'],
            {state: { encomienda: this.formulario.value.encomienda}});
        }
        else{
            this.router.navigate(['/buscar']).then(() =>
                this.router.navigate(['/buscar/comprobantes'],
                {state: { encomienda: this.formulario.value.encomienda }})
            );

        }
    }

    volver(){
      this.router.navigate(['/cargas/cruces']);
    }

    salir(){
        this.api.logout();
        this.router.navigate(['login']);
    }

}
