import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    isLogged: boolean;
    usuario: string = '';
    constructor(private auth: ApiService, private route: Router) {
        this.isLogged = this.auth.estaAutenticado();
        this.usuario = ' '+auth.usuario();
    }

    ngOnInit(): void {
    }

    salir(){
        this.auth.logout();
        this.route.navigate(['login']);
    }

}
