<div class="dash_container" align="center">
    <mat-card>
        <mat-card-title>
            <p style="text-align: right; font-size: 14px;">
                <button mat-raised-button (click)="comprobantes()" style="float: left;">
                    <i class="material-icons">document_scanner</i> Comprobantes
                </button>
                <button mat-raised-button (click)="agregar_persona()" style="float: left;">
                    <i class="material-icons">person_add</i> Agregar Personas
                </button>
                <button mat-raised-button (click)="salir()" style="margin-left: 10px;">
                    <mat-icon style="font: size 14px; color:red">exit_to_app</mat-icon> Salir
                </button>
            <br>Sesión iniciada como: {{usuario}}</p>Sistema de Cargas
        </mat-card-title>
        <mat-card-content>
            <!-- <mat-form-field appearance="fill" align="center">

                <mat-label>Escoja fecha</mat-label>
                <input matInput
                [matDatepicker]="date"
                formControlName="mes_cruce"
                #mes_cruce>
                
                <mat-datepicker-toggle [for]="date" matSuffix></mat-datepicker-toggle>
                <mat-datepicker
                #date
                startView="year"
                (monthSelected)="chosenMonthHandler($event, date)"></mat-datepicker>
            </mat-form-field> -->
            
            <form [formGroup]="formulario">
                <mat-form-field>
                    <input #mes_cruce matInput [matDatepicker]="dp" placeholder="Seleccionar Fecha" [formControl]="date">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp (monthSelected)="monthSelected($event, dp, mes_cruce)" startView="multi-year"></mat-datepicker>
                  </mat-form-field>
            </form>

            <!-- <mat-card-actions>
                <button mat-stroked-button (click)="cargar_viajes('cruces/lista_cargas', mes_cruce.value)"><mat-icon>assignment</mat-icon> Cargar</button>
            </mat-card-actions> -->


            <br><br>



        </mat-card-content>

    </mat-card>

    <!--<app-lista-cargas [nombre]="nombre"></app-lista-cargas>-->

    <router-outlet></router-outlet>

</div>
