import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { DreadnoughtComponent } from './pages/cargas/dreadnought/dreadnought.component';
import { BehemothComponent } from './pages/behemoth/behemoth.component';
import { AuthGuard } from './guard/auth.guard';
import { DashComponent } from './pages/cargas/dash/dash.component';
import { ReporteComponent } from './pages/cargas/reporte/reporte.component';
import { ResumenComponent } from './pages/cargas/resumen/resumen.component';
import { NaganComponent } from './pages/cargas/nagan/nagan.component';
import { ListaCargasComponent } from './pages/cargas/lista-cargas/lista-cargas.component';
import { ListaCargasFullComponent } from './pages/cargas/lista-cargas-full/lista-cargas-full.component';
import { ListaEnviosComponent } from './pages/cargas/lista-envios/lista-envios.component';
import { DetallecargaComponent } from './pages/cargas/detallecarga/detallecarga.component';
import { ImpresionComponent } from './pages/impresion/impresion.component';
import { BultoImpresionComponent } from './pages/bulto-impresion/bulto-impresion.component';
import { PlantillaImpresionComponent } from './pages/plantilla-impresion/plantilla-impresion.component';
import { ManifiestoCargaComponent } from './pages/manifiesto-carga/manifiesto-carga.component';
import { ModuloComprobantesComponent } from './pages/busqueda-comprobantes/modulo-comprobantes/modulo-comprobantes.component';
import { BuscarComponent } from './pages/busqueda-comprobantes/buscar/buscar.component';
import { PersonaComponent } from './pages/aregar_persona/persona.component';
import { RevisarEncomiendaComponent } from './pages/revisar-encomienda/revisar-encomienda.component';
import { EncomiendaResultadoComponent } from './pages/encomienda-resultado/encomienda-resultado.component';
import { BultosEstadoComponent } from './pages/cargas/bultos-estado/bultos-estado.component';
const routes: Routes = [
    // { path: '', component: RevisarEncomiendaComponent, children: [
    //     { path: 'resultado', component: EncomiendaResultadoComponent }
    // ]},
    // { path: 'login', component: LoginComponent },
    { path: '', component: LoginComponent },
    { path: 'impresion', component: ImpresionComponent },
    { path: 'bulto-impresion', component: BultoImpresionComponent },
    { path: 'login', component: LoginComponent },
    { path: 'plantilla_impresion', component: PlantillaImpresionComponent, canActivate: [AuthGuard ]}, 
    { path: 'home', component: HomeComponent, canActivate: [ AuthGuard ] },
    { path: 'buscar', component: BuscarComponent, canActivate: [ AuthGuard ], children: [
        { path: 'comprobantes', component: ModuloComprobantesComponent, canActivate: [ AuthGuard ] }
    ]},
    { path: 'agregar_persona', component: PersonaComponent, canActivate: [ AuthGuard ]},
    { path: 'cargas', component: DashComponent, canActivate: [ AuthGuard ], children: [
        { path: 'argone', component: DashComponent, canActivate: [ AuthGuard ] },
        { path: 'reporte', component: ReporteComponent, canActivate: [ AuthGuard ] },
        { path: 'cruces', component: NaganComponent, canActivate: [ AuthGuard ], children: [
            { path: 'nueva_encomienda', component: DreadnoughtComponent, canActivate: [ AuthGuard ] },
            { path: 'lista_cargas', component: ListaCargasComponent, canActivate: [ AuthGuard ] },
            { path: 'lista_envios', component: ListaEnviosComponent, canActivate: [ AuthGuard ] },
            { path: 'lista_envios_full', component: ListaCargasFullComponent, canActivate: [ AuthGuard ] },
            { path: 'bultos-estado/:id_cruce/:estado', component: BultosEstadoComponent, canActivate: [ AuthGuard ]},
            { path: 'manifiesto', component: ManifiestoCargaComponent, canActivate: [ AuthGuard ] },
            { path: 'resumen', component: ResumenComponent, canActivate: [ AuthGuard ] },
            { path: 'detalle_carga', component: DetallecargaComponent, canActivate: [ AuthGuard ] },
        ]},

    ] },
    { path: 'behemoth', component: BehemothComponent, canActivate: [ AuthGuard ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
