import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { DreadnoughtComponent } from './pages/cargas/dreadnought/dreadnought.component';
import { NaganComponent } from './pages/cargas/nagan/nagan.component';
import { MatRadioModule } from '@angular/material/radio';
// angular material
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NavbarComponent } from './navbar/navbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTable, MatTableModule} from '@angular/material/table';
import { BehemothComponent } from './pages/behemoth/behemoth.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { DashComponent } from './pages/cargas/dash/dash.component';
import { SidenavComponent } from './pages/cargas/sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ReporteComponent } from './pages/cargas/reporte/reporte.component';
import { ResumenComponent } from './pages/cargas/resumen/resumen.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { ListaCargasComponent } from './pages/cargas/lista-cargas/lista-cargas.component';
import { registerLocaleData } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import { ListaEnviosComponent } from './pages/cargas/lista-envios/lista-envios.component';
import { DetallecargaComponent } from './pages/cargas/detallecarga/detallecarga.component';
import { ImpresionComponent } from './pages/impresion/impresion.component';
import { DataTablesModule } from 'angular-datatables';
import { BultoImpresionComponent } from './pages/bulto-impresion/bulto-impresion.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { PlantillaImpresionComponent } from './pages/plantilla-impresion/plantilla-impresion.component';
import { ManifiestoCargaComponent } from './pages/manifiesto-carga/manifiesto-carga.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ComprobanteComponent } from './pages/comprobante/comprobante.component';
import { ComprobanteEntregaComponent } from './pages/comprobante-entrega/comprobante-entrega.component';
import { ComprobanteDevolucionComponent } from './pages/comprobante-devolucion/comprobante-devolucion.component';
import { ModuloComprobantesComponent } from './pages/busqueda-comprobantes/modulo-comprobantes/modulo-comprobantes.component';
import { BuscarComponent } from './pages/busqueda-comprobantes/buscar/buscar.component';
import { PersonaComponent } from './pages/aregar_persona/persona.component';
import { RevisarEncomiendaComponent } from './pages/revisar-encomienda/revisar-encomienda.component';
import { EncomiendaResultadoComponent } from './pages/encomienda-resultado/encomienda-resultado.component';
import { EncomiendaComponent } from './pages/encomienda/encomienda.component';
import { LoadingComponent } from './loading/loading.component';
import { ListaCargasFullComponent } from './pages/cargas/lista-cargas-full/lista-cargas-full.component';
import { RutModule } from 'rut-chileno';
import { BultosEstadoComponent } from './pages/cargas/bultos-estado/bultos-estado.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CambiarCruceComponent } from './pages/cargas/cambiar-cruce/cambiar-cruce.component';

registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        NavbarComponent,
        DreadnoughtComponent,
        BehemothComponent,
        DashComponent,
        SidenavComponent,
        ReporteComponent,
        ResumenComponent,
        NaganComponent,
        ListaCargasComponent,
        ListaEnviosComponent,
        DetallecargaComponent,
        ImpresionComponent,
        BultoImpresionComponent,
        PlantillaImpresionComponent,
        ManifiestoCargaComponent,
        ComprobanteComponent,
        ComprobanteEntregaComponent,
        ComprobanteDevolucionComponent,
        ModuloComprobantesComponent,
        BuscarComponent,
        PersonaComponent,
        RevisarEncomiendaComponent,
        EncomiendaResultadoComponent,
        EncomiendaComponent,
        LoadingComponent,
        ListaCargasFullComponent,
        BultosEstadoComponent,
        CambiarCruceComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatMenuModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDialogModule,
        RutModule,
        MatGridListModule,
        MatSidenavModule,
        MatListModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSortModule,
        DataTablesModule,
        NgxPrintModule,
        MatTableExporterModule,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    entryComponents: [ DetallecargaComponent ],
    providers: [
        CdkColumnDef,
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: LOCALE_ID, useValue: 'es' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
