import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { BultoImpresionComponent } from '../../bulto-impresion/bulto-impresion.component';

@Component({
  selector: 'app-detallecarga',
  templateUrl: './detallecarga.component.html',
  styleUrls: ['./detallecarga.component.css'],

})
export class DetallecargaComponent implements OnInit {

    encomiendas: any;
    id_cruce: number;
    constructor(public dialogRef: MatDialogRef<DetallecargaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private api: ApiService,public dialog: MatDialog) {
            // console.log(data);

            this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
            // this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
            // parche
            this.api.getEncomiendasCruce(this.id_cruce).subscribe(
                data => {
                    this.encomiendas = data.data;
                    console.log(this.encomiendas)
                }
            );

        }

    ngOnInit(): void {
    }
    cerrar(): void {
        this.dialogRef.close();
    }

    impresion(bultos: any, id_bulto: number): void {
        console.log(id_bulto);
        const dialogRef = this.dialog.open(BultoImpresionComponent,{
            width: '550px',
            data: {bultos: bultos, id_bulto: id_bulto}
        });
        dialogRef.afterClosed().subscribe( resp => {
            console.log(resp);
        });
    }

    eliminar(id_qr : number) : void{

        console.log('id_qr: '+id_qr);
        //elimina el bulto de la BD
        this.api.eliminarBulto(id_qr);

        // console.log(resp);

        console.log(this.data);

        const bultos : any[] = this.data.bultos;

        //elimina el bulto de la lista
        bultos.forEach( (bulto : any, index : number) => {
            console.log(bulto);
            if (bulto.id_qr == id_qr) this.data.bultos.splice(index,1);
            console.log('bultos: '+bultos.length)
            if(bultos.length == 0){
                this.dialogRef.close();

                this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
                // this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
                // parche
                this.api.getEncomiendasCruce(this.id_cruce).subscribe(
                    data => {
                        this.encomiendas = data.data;
                        console.log(this.encomiendas)
                    }
                );
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl],{state: { id_cruce: sessionStorage.getItem('id_cruce') }});
                    console.log(currentUrl);
                });
            }
        });
    }
}


