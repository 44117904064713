<div class="dash_container" align="center">
    <mat-card>
        <mat-card-title>
            <p style="text-align: right; font-size: 14px;">
                <button mat-raised-button (click)="ingresar()" style="margin-left: 10px;">
                     Ingresar
                </button>
        </mat-card-title>
        <mat-card-content>
            <div class="container">
                
                <form [formGroup]="formulario"
                name='formulario'
                (ngSubmit)='cargar_contenido()'>
                Nº de Encomienda<br>
                    <input
                    type='string'
                    autocomplete="off"
                    style="width: 150px;"
                    class="input_item"
                    type="number"
                    formControlName='encomienda'>
                    <br><br>
                    RUT Destinatario<br>
                    <input
                    type='string'
                    autocomplete="off"
                    style="width: 150px;"
                    class="input_item"
                    formControlName='rut'
                    placeholder="Ej: 12345678-9">
                    <br>
                    <br>
                    <button mat-stroked-button>Cargar</button>
                </form>
            </div>
            <br><br>



        </mat-card-content>

    </mat-card>

    <!--<app-lista-cargas [nombre]="nombre"></app-lista-cargas>-->

    <router-outlet></router-outlet>

</div>
