import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revisar-encomienda',
  templateUrl: './revisar-encomienda.component.html',
  styleUrls: ['./revisar-encomienda.component.css']
})
export class RevisarEncomiendaComponent implements OnInit {

  formulario: FormGroup;

  encomienda: string='';
  rut: string='';

  constructor(private router: Router,private api: ApiService, private fb: FormBuilder) {
    this.formulario = new FormGroup({
        encomienda: new FormControl(''),
        rut: new FormControl('')
    });
}

  ngOnInit(): void {
  }

  cargar_contenido (){
    console.log(`rut= ${JSON.stringify(this.formulario.value.rut)}`)

    if (this.formulario.value.encomienda=='' || this.formulario.value.rut ==''){
        Swal.fire({
            title: 'Atención',
            text: 'Ambos Campos son Requeridos'
        });
    }
    else if(this.router.url == '/'){
        this.router.navigate(['/resultado'],
        {state: { encomienda: this.formulario.value}});
    }
    else{
        this.router.navigate(['/']).then(() =>
            this.router.navigate(['/resultado'],
            {state: { encomienda: this.formulario.value }})
        );

    }
  }
  ingresar(){
    this.router.navigate(['login']);
  }

}
