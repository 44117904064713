<div class="dash_container" align="center">
    <mat-card>
        <mat-card-title>
            <p style="text-align: right; font-size: 14px;">
                <button mat-raised-button (click)="volver()" style="float: left;">
                    <i class="material-icons">keyboard_backspace</i> Volver
                </button>
                <button mat-raised-button (click)="salir()" style="margin-left: 10px;">
                    <mat-icon style="font: size 14px; color:red">exit_to_app</mat-icon> Salir
                </button>
            <br>Sesión iniciada como: {{usuario}}</p>Comprobante
        </mat-card-title>
        <mat-card-content>

            <form [formGroup]="formulario"
            name='formulario'
            (ngSubmit)='cargar_contenido()'>
                <input
                type='number'
                autocomplete="off"
                placeholder="Nº de Encomienda"
                style="width: 150px;"
                class="input_item"
                formControlName='encomienda'>
                <br>
                <br>
                <button mat-stroked-button>Cargar</button>
            </form>

            <br><br>

        </mat-card-content>

    </mat-card>

    <!--<app-lista-cargas [nombre]="nombre"></app-lista-cargas>-->

    <router-outlet></router-outlet>

</div>
