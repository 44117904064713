<h1 mat-dialog-title>
    Reasignar Cruce
</h1>
<mat-dialog-content>
<mat-card>
    <mat-card-content>
        <mat-label>Selecciona un cruce</mat-label>
        <mat-select (selectionChange)="cruceSeleccionado($event.value)">
            <mat-option *ngFor="let cruce of cruces" [value]="cruce">
              {{cruce.horario_cruce}} ({{cruce.tramo.nombre_tramo}})
            </mat-option>
          </mat-select>
          <br><br>
          <mat-form-field appearance="fill">
            <mat-label>Lote</mat-label>
            <input style="width: 500px;" matInput type="number" min="0" pattern="\d*" [(ngModel)]="lote">
          </mat-form-field>
    </mat-card-content>
    <div *ngIf="lote && cruce_seleccionado">
        <button mat-raised-button *ngIf="cruce_seleccionado" (click)="asignarYCerrar()">
            Aceptar
        </button>
    </div>
</mat-card>
</mat-dialog-content>
