import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cambiar-cruce',
  templateUrl: './cambiar-cruce.component.html',
  styleUrls: ['./cambiar-cruce.component.css']
})
export class CambiarCruceComponent implements OnInit {

  cruces: any
  encomiendas: any  
  cruce_seleccionado: any = false;
  lote: any;


  constructor(public dialogRef: MatDialogRef<CambiarCruceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private api: ApiService,public dialog: MatDialog) { 
      console.log('data recibida')
      console.log(data)
      this.encomiendas = data.id_encomienda
      this.api.getCrucesCambiar().subscribe(
        data => {
          this.cruces = data
          console.log('cruces')
          console.log(data)
      })

    }

  ngOnInit(): void {

  }
  cruceSeleccionado(cruce:any) {
    this.cruce_seleccionado = cruce;
  }

  asignarYCerrar() {
    const payload = {
      id_cruce: this.cruce_seleccionado.id_cruce,
      lote: this.lote,
      encomiendas: this.encomiendas
    }
    this.api.asignarNuevoCruce(payload).subscribe(
      resultado => {
        resultado = resultado
        console.log('respuestita')
        console.log(resultado)
        this.dialogRef.close();
        location.reload();
    })
    // if (resultado) {
    // }
  }
  cerrar(): void {
    this.dialogRef.close();
  }

}
