<div id = "demo">
    <table align="center" *ngFor="let b of bultos" style="margin-bottom: 10px; height: 9.5cm !important; width: 4.5cm !important; margin-bottom:20px; transform: scale(0.83);"> 
        <tbody >
            <tr>
                <td colspan="5" style="font-weight: bold; font-size: 18px; text-align: left;">{{b.persona_recibe[0].nombre}} {{b.persona_recibe[0].apellido}}</td>
            </tr>
            
            <tr align="center">
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].email}}</td>
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].telefono}}</td>
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="1">{{b.persona_recibe[0].rut}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].residencia_direccion}}</td>
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].residencia_ciudad}}</td>
                <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="1">{{b.persona_recibe[0].tipo}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
            </tr>
            <tr>
                <td colspan="5" style="font-weight: bold; font-size: 14px; text-align: left;">{{b.persona_envia[0].nombre}} {{b.persona_envia[0].apellido}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_envia[0].email}}</td>
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_envia[0].telefono}}</td>
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="1">{{b.persona_envia[0].rut}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
            </tr>
            <tr>
                <td colspan="5" style="font-weight: bold; font-size: 14px; text-align: left;">{{b.id_bulto}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">Rec. {{b.recepcionado}}</td>
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.nombre}}</td>
                <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="1">{{b.peso}}Kg.</td>
            </tr>
            <tr align="center">
            </tr>
            <tr>
                <td style="font-size:13px; font-weight: bold;" colspan="5">{{b.descripcion}}</td>
            </tr>
            <tr>
            <td style="text-align: center;" colspan="5"><img src="{{b.image}}" alt=""></td>
        </tr>
        <tr>
            <td style="font-size:14px; font-weight: bold;" colspan="3">Origen: {{b.origen}}</td>
            <td rowspan = "2" colspan="2" style="font-size: 40px; font-weight: bold;text-align: right;">LOTE {{b.numero_lote}}</td>
        </tr>
        <tr>
            <td style="font-size:124x; font-weight: bold;" colspan="2">Destino: {{b.destino}}</td>
        </tr>
        <tr align="center">
            <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
        </tr>
        <tr>
            <td align="center" colspan = "5"style="font-size: 10px; font-weight: bold">TABSA; RUT: 82.074.900-6 — Juan Williams 06450; tel: +(56) (61) 2728100</td>
        </tr>
    </tbody>
</table>
</div>