<mat-card>
    <mat-card-title></mat-card-title>

    <mat-card-content >
        <br><br>
        <div class="mat-elevation-z8">
        <mat-table [dataSource]="encomiendas">

            <ng-container matColumnDef="persona_envia">
                <mat-header-cell *matHeaderCellDef> Persona Envía </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="persona_envia"> {{element.persona_envia}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="persona_recibe">
                <mat-header-cell *matHeaderCellDef> Persona Recibe </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="persona_recibe"> {{element.persona_recibe}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="imprimir_comprobante_r" align="left">
                <mat-header-cell *matHeaderCellDef style="text-align:left"> Comprobante Recibo </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="COMPROBANTE RECIBO" style="text-align:left"> <mat-icon (click)="impresionComprobante(element.bultos, element.id_encomienda); " style="text-align: center;">print</mat-icon> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="imprimir_comprobante_e" align="left">
                <mat-header-cell *matHeaderCellDef style="text-align:left"> Comprobante Entrega </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="COMPROBANTE ENTREGA" style="text-align:left"> <mat-icon (click)="impresionComprobanteEntrega(element.bultos, element.id_encomienda); " style="text-align: center;">print</mat-icon> </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
        </mat-table>
        </div>
    </mat-card-content>
</mat-card>
