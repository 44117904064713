<!-- <mat-card>
    <mat-card-title>
        Encomiendas para el viaje
    </mat-card-title>
    <mat-card-actions>
        <button (click)="nueva_encomienda(id_cruce)" mat-raised-button>
            Nuevo<mat-icon>note_add</mat-icon>
        </button>
      </mat-card-actions>
    <mat-card-content>
        <table>
            <thead>
                <tr>
                    <th width='15%'>Encomienda</th>
                    <th>Lote</th>
                    <th>Envia</th>
                    <th>Recibe</th>
                    <th width="10%">Contenido</th>
                    <th width="10%">Imprimir</th>
                </tr>
            </thead>
            <tbody *ngFor="let lote of encomiendas;">
                    <tr *ngFor="let e of lote.encomienda; let j=index;">
                        <td align='center'>{{ e.id_encomienda }}</td>
                        <td>{{ lote.numero_lote }}</td>
                        <td>{{ e.id_persona_envia }}</td>
                        <td>{{ e.id_persona_recibe }}</td>
                        <td align='center'>
                            <mat-icon (click)="abrir_detalle(e.bulto, e.id_encomienda)" >description</mat-icon>
                        </td>
                        <td align='center'>
                            <mat-icon (click)="impresion(e.bulto, e.id_encomienda)">print</mat-icon>
                        </td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>

</mat-card> -->

<mat-card>

    <mat-card-title>
        Fecha de Cruce: {{horario}}
    </mat-card-title>
</mat-card>
<mat-card>
    <mat-card-title>
    </mat-card-title>
    <mat-card-actions>
        <button (click)="nueva_encomienda(id_cruce)" mat-raised-button>
            <mat-icon>note_add</mat-icon>Nuevo
        </button>
        <button (click)="manifiesto(id_cruce)" mat-raised-button>
            <mat-icon>assignment</mat-icon>Manifiesto
        </button>
        <button (click)="lista_full(id_cruce)" mat-raised-button>
            <mat-icon>ballot</mat-icon>Todas las Encomiendas
        </button>
        <button (click)="bultos_estado(id_cruce, 1)" mat-raised-button>
            Bultos Recibidos ({{recibidos}})
        </button>
        <button (click)="bultos_estado(id_cruce, 3)" mat-raised-button>
            Bultos En Viaje ({{enviaje}})
        </button>
        <button (click)="bultos_estado(id_cruce, 4)" mat-raised-button>
            Bultos Arribados ({{arribados}})
        </button>
    </mat-card-actions>
    <br>
    <mat-card-content>
        <div class="mat-elevation-z8">
            <ng-container>
                <span></span>
                <button (click)="refresh(id_cruce)"><i class="material-icons">refresh</i></button><br><br>

            </ng-container>
            <ng-container>
                <span>* LISTA DE 20 ENCOMIENDAS</span>
               <br><br>

            </ng-container>

            <!-- <div class="buscador">
                <input type="text" name="search" [(ngModel)]="searchText" placeholder="Buscar Destinatario">
            </div>

            <table class="table table-striped">
                <thead>
                    <th>ID</th>
                    <th>Lote</th>
                    <th>Remitente</th>
                    <th>Destinatario</th>
                    <th>Detalle</th>
                    <th>Ticket</th>
                    <th>Cantidad Bultos</th>
                    <th>Cantidad Entregados</th>
                </thead>
                <tbody [ngStyle]="{'background-color':e.bultos.length == e.cantidad ? 'green' : '' }"  *ngFor="let e of encomiendas">
                    <td style="text-align: center;">{{e.id_encomienda}}</td>
                    <td style="text-align: center;">{{e.id_lote}}</td>
                    <td style="text-align: center;">{{e.persona_envia[0].nombre + ' ' + e.persona_envia[0].apellido | uppercase}}</td>
                    <td style="text-align: center;">{{e.persona_recibe[0].nombre + ' ' + e.persona_recibe[0].apellido | uppercase}}</td>
                    <td style="text-align: center;"><mat-icon (click)="abrir_detalle(e.bultos, e.id_encomienda)">description</mat-icon></td>
                    <td style="text-align: center;"><mat-icon (click)="impresion(e.bultos, e.id_encomienda)">print</mat-icon></td>
                    <td style="text-align: center;">{{e.bultos.length}}</td>
                    <td style="text-align: center;">{{e.cantidad}}</td>
                </tbody>
            </table> -->

            <mat-form-field>
                <input (keyup)="applyFilter($event.target)" matInput placeholder="Buscar">
            </mat-form-field>    

            <!-- <p style="text-align: right; margin-right: 2vw;">{{entregados.cantidad}} / {{suma_total.cantidad}}</p> -->

            <mat-table class="table table-striped" [dataSource]="dataSource" matSort > 
                <ng-container matColumnDef="id" align="left">
                  <mat-header-cell width="4%" *matHeaderCellDef mat-sort-header="id" style="text-align:left"> ID </mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="ID" style="text-align:left" matTooltip="{{element.bultos[0] ? element.bultos[0].descripcion : ''}}" [ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{id_cruce+element.id_encomienda}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lote" align="left">
                  <mat-header-cell *matHeaderCellDef style="text-align:left"> LOTE </mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="LOTE" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.numero_lote}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="envia" align="left">
                    <mat-header-cell *matHeaderCellDef style="text-align:left"> REMITENTE </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="REMITENTE" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.persona_envia}} </mat-cell>
                  </ng-container>

                <ng-container matColumnDef="recibe" align="left">
                    <mat-header-cell *matHeaderCellDef style="text-align:left"> DESTINATARIO </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="DETINATARIO" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.persona_recibe}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="descripcion" align="left">
                    <mat-header-cell *matHeaderCellDef style="text-align:left"> DETALLE </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="DETALLE" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> <mat-icon (click)="abrir_detalle(element.bultos, element.id_encomienda)" style="text-align: center;">description</mat-icon> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="imprimir_ticket" align="left">
                    <mat-header-cell *matHeaderCellDef style="text-align:left"> TICKET </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="TICKET" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> <mat-icon (click)="impresion(element.bultos, element.id_encomienda)" style="text-align: center;">print</mat-icon> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cantidad_bultos" align="left">
                    <mat-header-cell *matHeaderCellDef style="text-align:left"> TOTAL <br>({{suma_total.cantidad}}) </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="CANTIDAD BULTOS" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.bultos.length}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="entregados" align="left">
                    <mat-header-cell (click)="bultos_estado(id_cruce,6)"  *matHeaderCellDef style="text-align:left"> <button mat-raised-button style="font-size: 10px !important"> ENTREGADOS ({{entregados.cantidad}}) </button> </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="CANTIDAD ENTREGADOS" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.cantidad}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="para_envio" align="left">
                    <mat-header-cell (click)="bultos_estado(id_cruce,2)"  *matHeaderCellDef style="text-align:left"> <button mat-raised-button style="font-size: 10px !important"> PARA ENVÍO ({{ppenvio}}) </button> </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="CANTIDAD PARA ENVIO" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.envio}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="para_entrega" align="left">
                    <mat-header-cell (click)="bultos_estado(id_cruce,5)"  *matHeaderCellDef style="text-align:left"> <button mat-raised-button style="font-size: 10px !important"> PARA ENTREGA ({{ppentrega}}) </button> </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="CANTIDAD PARA ENTREGA" style="text-align:left"[ngStyle]="{'background-color':element.bultos.length == element.cantidad ? 'green' : '' }"> {{element.entrega}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
            </mat-table> 
        </div>
    </mat-card-content>

</mat-card>
