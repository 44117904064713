import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketI } from 'src/app/models/ticket.interface';

@Component({
  selector: 'app-bulto-impresion',
  templateUrl: './bulto-impresion.component.html',
  styleUrls: ['./bulto-impresion.component.css']
})
export class BultoImpresionComponent implements OnInit {

  bulto: any;
  id_cruce: number;
  id_bulto! : number;
  bultos! : TicketI[];
  prueba! : any[];

  constructor(public dialogRef: MatDialogRef<BultoImpresionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any | TicketI, private route: Router, private api: ApiService,public dialog: MatDialog) {
          console.log(data);
          console.log(`id qr ${data.bultos}`);
          this.id_bulto = data.bultos
          this.id_cruce = this.route.getCurrentNavigation()?.extras.state?.id_cruce;
          this.api.getTicket(this.id_bulto).subscribe(
            data => {
              this.bultos = data;
              this.prueba = this.bultos;
              console.log(this.bultos)
              }
            );
      }



      // constructor(public dialogRef: MatDialogRef<ImpresionComponent>,
      //   @Inject(MAT_DIALOG_DATA) public data: TicketI, private router: Router, private api: ApiService,public dialog: MatDialog) {
      //       console.log(data.id_encomienda);
      //       // this.prueba = "prueba";
      //
      //   }
      
      
      
      printPage() {
        this.dialog.closeAll();
        this.route.navigate(['/plantilla_impresion'],{state: { data: this.data }});
      }

        ngOnInit(): void {

        }

}
