import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Item } from 'src/app/models/item.interface';
import { HttpClient, HttpClientModule } from '@angular/common/http';


// klxk30
export class fila_tabla {
    // id_encomienda: number=0;
    peso: number=0;
    largo: number=0;
    cantidad: number=1;
    descripcion: string='';
    id_item: any='';
}

export interface formulario_carga {
    id_usuario: FormControl
    id_lote: FormControl
    id_cruce: FormControl
    id_usuario_ad: FormControl
    rut_cargador: FormControl
    pasaporte_cargador: FormControl
    numero_lote: FormControl
    usuario_desc: FormControl
    //: FormControl
    valor: FormControl
    volumen: FormControl
    tipo_documento_cargador: FormControl
    tipo_documento_destinatario: FormControl
    id_persona_envia: FormControl
    nombre_cargador: FormControl
    apellido_cargador: FormControl
    telefono_cargador: FormControl
    email_cargador: FormControl
    residente_cargador: FormControl
    rut_destinatario: FormControl
    pasaporte_destinatario: FormControl
    id_persona_recibe: FormControl
    nombre_destinatario: FormControl
    apellido_destinatario: FormControl
    telefono_destinatario: FormControl
    email_destinatario: FormControl
    residente_destinatario: FormControl
    nombre_tramo: FormControl
}


export function validarCampos() : ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        return {data:true};
    }
} 

@Component({
    selector: 'app-dreadnought',
    templateUrl: './dreadnought.component.html',
    styleUrls: ['./dreadnought.component.css']
})

export class DreadnoughtComponent implements OnInit {

    url = "https://api-carga.tabsa.cl/api/encomienda"

	formulario_carga: FormGroup;
    control_option = new FormControl();
    items: Item[];
    filteredOptions: Observable<Item[]>;

    texto_item: string='';
    numeros: number=3;
	filas: Array<any> = [...Array(this.numeros).keys()];
	usuario: string = '';
	tramos: Array<any>=[];
    name: string='';
    id_cruce: number;
    nuevo_cargador : boolean = false;
    nuevo_remitente : boolean = false;
    disable_resiente: boolean = true;
    dpd: boolean = false
    dpc: boolean = false


    ngOnInit(): void {

    }

	constructor(private api: ApiService, private _http:HttpClient, private fb: FormBuilder, private route: Router) {

        this.id_cruce = this.route.getCurrentNavigation()?.extras.state?.id_cruce;
        this.items = [];
        this.usuario = ' '+api.usuario();
        this.getTramos();
        this.getItems();
        this.formulario_carga = new FormGroup({
            //tramo:                new FormControl('',[Validators.required]),
            id_usuario: new FormControl(1, validarCampos),
            id_lote: new FormControl(1, validarCampos),
            id_cruce: new FormControl(this.id_cruce, validarCampos),
            id_usuario_ad: new FormControl(sessionStorage.getItem('id'), validarCampos),
            tipo_documento_cargador: new FormControl('',[Validators.required], ),
            rut_cargador: new FormControl('',[Validators.required], ),
            pasaporte_cargador: new FormControl('',[Validators.required], ),
            numero_lote: new FormControl('',[Validators.required], ),
            valor: new FormControl('',[Validators.required], ),
            usuario_desc: new FormControl('',[Validators.required]), //),
            volumen: new FormControl('',[Validators.required], ),
            id_persona_envia: new FormControl(0, validarCampos),
            nombre_cargador: new FormControl('',[Validators.required], ),
            apellido_cargador: new FormControl('',[Validators.required], ),
            telefono_cargador: new FormControl(null, validarCampos),
            email_cargador: new FormControl(null, validarCampos),
            residente_cargador: new FormControl(null, validarCampos),
            tipo_documento_destinatario: new FormControl('',[Validators.required], ),
            rut_destinatario: new FormControl('',[Validators.required], ),
            pasaporte_destinatario: new FormControl('',[Validators.required], ),
            id_persona_recibe: new FormControl(0, validarCampos),
            nombre_destinatario: new FormControl('',[Validators.required], ),
            apellido_destinatario: new FormControl('',[Validators.required], ),
            telefono_destinatario: new FormControl(null, validarCampos),
            email_destinatario: new FormControl(null, validarCampos),
            residente_destinatario: new FormControl(null, validarCampos),
            nombre_tramo: new FormControl(null, validarCampos),

            bulto: this.fb.array([
                this.fb.group(new fila_tabla())
            ]),
            nuevo_lote: new FormControl(null)
        });
        console.log('formulario = '+this.formulario_carga.value);
        this.filteredOptions = this.formulario_carga.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? 'value' : 'value.nombre')),
            map(nombre => (nombre ? this._filtro(nombre) : this.items.slice()))
        );
	}

    displayFn(options: Item[]): (id: number) => string {
        return (id: number) => {
            const nombre_item = Array.isArray(options) ? options.find(option => option.id_item === id) : null;
            return nombre_item ? nombre_item.nombre : '';
        }
    }

    

    buscar_item(event: any, indice: number){
        this.texto_item = event.target.value;
        this.filteredOptions = this.formulario_carga.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : 'value.nombre')),
            map(nombre => (this.texto_item ? this._filtro(this.texto_item) : this.items.slice()))
        );
    }

    get fila(){
        return this.formulario_carga.get('bulto') as FormArray;
    }

    agregar_fila(){
        this.fila.push(this.fb.group(new fila_tabla()));
    }

    borrar_linea(i: number){
        this.fila.removeAt(i);
    }


    formatear_rut(ind: number){
        var rut: string='';
        if(ind == 1){
            if (this.formulario_carga.value.tipo_documento_cargador!=2){
                rut = this.api.formatear_rut(this.formulario_carga.value.rut_cargador, false);
                this.formulario_carga.get('rut_cargador')?.setValue(rut);
                // this.formulario_carga.value.rut_cargador.setValue(rut);
            }
        }else{
            if (this.formulario_carga.value.tipo_documento_destinatario!=2){
                rut = this.api.formatear_rut(this.formulario_carga.value.rut_destinatario, false);
                this.formulario_carga.get('rut_destinatario')?.setValue(rut);
                // this.formulario_carga.value.rut_cargador.setValue(rut);
            }
        }
    }

    validar_rut(id: any){
        return true;
    }

    validar(fc : formulario_carga/*, ft: fila_tabla []*/){

        let val = false;
        let o = 0;
        let val_bul = this.formulario_carga.value.bulto
        console.log(fc)
        console.log('entra1')
        console.log('tipo_identificacion')
        console.log(fc.tipo_documento_cargador)
        let tdc = this.formulario_carga.value.tipo_documento_cargador
        let tdd = this.formulario_carga.value.tipo_documento_destinatario
        console.log('pruebaza')
        console.log(tdc)
        if(o == 0){
            if(tdc == 2){
                if(fc.pasaporte_cargador){
                    this.dpc = true
                }else{
                    this.dpc = false
                }
            }else{
                if(fc.rut_cargador){
                    this.dpc = true
                }else{
                    this.dpc = false
                }
            }

            if(tdd == 2){
                if(fc.pasaporte_destinatario){
                    this.dpd = true
                }else{
                    this.dpd = false
                }
            }else{
                if(fc.rut_destinatario){
                    this.dpd = true
                }else{
                    this.dpd = false
                }
            }

            // console.log('entra2')
            if(!fc.nombre_cargador || !fc.apellido_cargador || !fc.telefono_cargador || !fc.email_cargador || !fc.email_destinatario || !this.dpd || !this.dpc || !fc.nombre_destinatario || !fc.apellido_destinatario || !fc.telefono_destinatario || !fc.volumen/* || !fc.usuario_desc */|| !fc.numero_lote || !fc.valor) val = true

            console.log('val')
            console.log(val)
        }
        // val_bul.forEach(e => {
        //     if(!e.valor)
        // });
        return val;
    }

    // duplicar_linea(i: number){
    //
    // }

    omit_special_char(event:any){   
        var k;  
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return(k < 190); 
    }
    

    get getnombre(): string{
        return this.formulario_carga.get('nombre_cargador')?.value;
    }

	getTramos(){
        const id_tramo = 3;
		this.api.tramos(id_tramo)
	}

    getItems(){
        this.api.getItems().subscribe( data => {
            this.items = data;
            //console.log(this.items);
        });
    }

    //OBSOLETO
	// buscar_rut(rut: String, cargador=0){
    //     rut = rut.replace(/\./g, '');
    //     if (cargador)
    //         this.formulario_carga.controls['rut_cargador'].setValue(rut);
    //     else this.formulario_carga.controls['rut_destinatario'].setValue(rut);
	// 	this.api.getDatosPersonales(rut).subscribe( data => {
	// 		if (data){
	// 			if (cargador){
	// 				this.formulario_carga.get('nombre_cargador')?.setValue(data[0]['nombre']);
	// 				this.formulario_carga.get('apellido_cargador')?.setValue(data[0]['apellido']);
	// 				this.formulario_carga.get('telefono_cargador')?.setValue(data[0]['telefono']);
    //                 // this.formulario_carga.get('id_persona_envia')?.setValue();
	// 			}else{
	// 				this.formulario_carga.get('nombre_destinatario')?.setValue(data[0]['nombre']);
	// 				this.formulario_carga.get('apellido_destinatario')?.setValue(data[0]['apellido']);
	// 				this.formulario_carga.get('telefono_destinatario')?.setValue(data[0]['telefono']);
    //                 this.nuevo_remitente = false;
	// 			}
	// 		}else{
	// 			console.log("no hay datos");
	// 		}
	// 	})
	// }

    getPersonaByPasaporte(pasaporte : string, tipo : number){
        this.api.getPersonaByPasaporte(pasaporte).subscribe( data => {
            let persona : any[] = data;
            console.log('respuesta gpbpasaporte');
            console.log(persona);
            if(persona.length > 0){
                console.log('entra a gpbpasaporte 1');
                //Encontró rut
                if (tipo == 1) {
                    console.log('entra a gpbpasaporte 2');

                    this.nuevo_cargador = true
                    this.formulario_carga.get('nombre_cargador')?.setValue(persona[0]['nombre']);
					this.formulario_carga.get('apellido_cargador')?.setValue(persona[0]['apellido']);
					this.formulario_carga.get('telefono_cargador')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('email_cargador')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('residente_cargador')?.setValue(persona[0]['residente']);
					this.formulario_carga.get('id_persona_envia')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_envia ${ this.formulario_carga.get('id_persona_envia: ')?.value }`);
                    this.nuevo_cargador = false;
                }else{
                    console.log('entra a gpbpasaporte 3');
                    this.nuevo_remitente = true
                    this.formulario_carga.get('nombre_destinatario')?.setValue(persona[0]['nombre']);
                    this.formulario_carga.get('apellido_destinatario')?.setValue(persona[0]['apellido']);
                    this.formulario_carga.get('telefono_destinatario')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('residente_destinatario')?.setValue(persona[0]['residente']);
                    this.formulario_carga.get('email_destinatario')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('id_persona_recibe')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_recibe ${ this.formulario_carga.get('id_persona_recibe: ')?.value }`);
                    this.nuevo_remitente = false;
                }
            }else{
                console.log('entra a gpbpasaporte 4');

                //No encontro rut por ende se deja bandera para insertar datos
                if (tipo == 1) {
                    console.log('entra a gpbpasaporte 5');

                    this.nuevo_cargador = true
                    this.formulario_carga.get('nombre_cargador')?.setValue(persona[0]['nombre']);
					this.formulario_carga.get('apellido_cargador')?.setValue(persona[0]['apellido']);
					this.formulario_carga.get('telefono_cargador')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('email_cargador')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('residente_cargador')?.setValue(persona[0]['residente']);
					this.formulario_carga.get('id_persona_envia')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_envia ${ this.formulario_carga.get('id_persona_envia: ')?.value }`);
                    this.nuevo_cargador = false;
                }else {
                    console.log('entra a gpbpasaporte 6');
                    this.nuevo_remitente = true
                    this.formulario_carga.get('nombre_destinatario')?.setValue(persona[0]['nombre']);
                    this.formulario_carga.get('apellido_destinatario')?.setValue(persona[0]['apellido']);
                    this.formulario_carga.get('telefono_destinatario')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('residente_destinatario')?.setValue(persona[0]['residente']);
                    this.formulario_carga.get('email_destinatario')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('id_persona_recibe')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_recibe ${ this.formulario_carga.get('id_persona_recibe: ')?.value }`);
                    this.nuevo_remitente = false;
                }
                console.log('No encontró datos');
            }
            console.log(`nuevo cargador : ${this.nuevo_cargador}`);
            console.log(`nuevo remitente : ${this.nuevo_remitente}`);
        });
    }
    getPersonaByRut(rut : string, tipo : number){
        rut = rut.replace(/\./g, '');
        this.api.getPersonaByRut(rut).subscribe( data => {
            let persona : any[] = data;
            console.log(persona);
            if(persona.length > 0){
                //Encontró rut
                if (tipo == 1) {
                    this.nuevo_cargador = true
                    this.formulario_carga.get('nombre_cargador')?.setValue(persona[0]['nombre']);
					this.formulario_carga.get('apellido_cargador')?.setValue(persona[0]['apellido']);
					this.formulario_carga.get('telefono_cargador')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('email_cargador')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('residente_cargador')?.setValue(persona[0]['residente']);
					this.formulario_carga.get('id_persona_envia')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_envia ${ this.formulario_carga.get('id_persona_envia: ')?.value }`);
                    this.nuevo_cargador = false;
                }else{
                    this.nuevo_remitente = true
                    this.formulario_carga.get('nombre_destinatario')?.setValue(persona[0]['nombre']);
                    this.formulario_carga.get('apellido_destinatario')?.setValue(persona[0]['apellido']);
                    this.formulario_carga.get('telefono_destinatario')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('residente_destinatario')?.setValue(persona[0]['residente']);
                    this.formulario_carga.get('email_destinatario')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('id_persona_recibe')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_recibe ${ this.formulario_carga.get('id_persona_recibe: ')?.value }`);
                    this.nuevo_remitente = false;
                }
            }else{
                //No encontro rut por ende se deja bandera para insertar datos
                if (tipo == 1) {
                    this.nuevo_cargador = true
                    this.formulario_carga.get('nombre_cargador')?.setValue(persona[0]['nombre']);
					this.formulario_carga.get('apellido_cargador')?.setValue(persona[0]['apellido']);
					this.formulario_carga.get('telefono_cargador')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('email_cargador')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('residente_cargador')?.setValue(persona[0]['residente']);
					this.formulario_carga.get('id_persona_envia')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_envia ${ this.formulario_carga.get('id_persona_envia: ')?.value }`);
                    this.nuevo_cargador = false;
                }else {
                    this.nuevo_remitente = true
                    this.formulario_carga.get('nombre_destinatario')?.setValue(persona[0]['nombre']);
                    this.formulario_carga.get('apellido_destinatario')?.setValue(persona[0]['apellido']);
                    this.formulario_carga.get('telefono_destinatario')?.setValue(persona[0]['telefono']);
                    this.formulario_carga.get('residente_destinatario')?.setValue(persona[0]['residente']);
                    this.formulario_carga.get('email_destinatario')?.setValue(persona[0]['email']);
                    this.formulario_carga.get('id_persona_recibe')?.setValue(persona[0]['persona_carga'][0]['id_persona_carga']);
                    console.log(`id_persona_recibe ${ this.formulario_carga.get('id_persona_recibe: ')?.value }`);
                    this.nuevo_remitente = false;
                }
                console.log('No encontró datos');
            }
            console.log(`nuevo cargador : ${this.nuevo_cargador}`);
            console.log(`nuevo remitente : ${this.nuevo_remitente}`);
        });
    }

	guardar_formulario(){
        if (this.nuevo_cargador || this.nuevo_remitente){
            if (this.nuevo_cargador){
                let nuevo : any = {
                    rut     : this.formulario_carga.get('rut_cargador')?.value,
                    pasaporte     : this.formulario_carga.get('pasaporte_cargador')?.value,
                    nombre  : this.formulario_carga.get('nombre_cargador')?.value,
                    apellido: this.formulario_carga.get('apellido_cargador')?.value,
                    telefono: this.formulario_carga.get('telefono_cargador')?.value,
                    email   : this.formulario_carga.get('email_cargador')?.value,
                    residente   : this.formulario_carga.get('residente_cargador')?.value
                }
                console.log(`OBJ nuevo cargador : ${nuevo}`);
                this.api.postPersona(nuevo).subscribe( data => {
                    let id_persona_carga_envia = data.data.id_persona_carga;
                    this.formulario_carga.get('id_persona_recibe')?.setValue(id_persona_carga_envia);
                    console.log(`id_persona_envia ${ this.formulario_carga.get('id_persona_envia')?.value }`);
                });
            }
            if (this.nuevo_remitente){
                let nuevo : any = {
                    rut     : this.formulario_carga.get('rut_destinatario')?.value,
                    pasaporte     : this.formulario_carga.get('pasaporte_destinatario')?.value,
                    nombre  : this.formulario_carga.get('nombre_destinatario')?.value,
                    apellido: this.formulario_carga.get('apellido_destinatario')?.value,
                    telefono: this.formulario_carga.get('telefono_destinatario')?.value,
                    email   : this.formulario_carga.get('email_destinatario')?.value,
                    residente: this.formulario_carga.get('residente_destinatario')?.value
                }
                console.log(`OBJ nuevo destinatario : ${nuevo}`);
                this.api.postPersona(nuevo).subscribe( data => {
                    let id_persona_carga_recibe = data.data.id_persona_carga;
                    this.formulario_carga.get('id_persona_envia')?.setValue(id_persona_carga_recibe);
                    console.log(`id_persona_recibe ${ this.formulario_carga.get('id_persona_recibe')?.value }`);
                });
            }
            this.guardar_encomienda()
        }else{
            this.guardar_encomienda()
        }
	}


    guardar_encomienda(){
        // if (this.formulario_carga.valid){
        //     let bultos = this.formulario_carga.get('bulto') as FormArray;
        //     let test = this.formulario_carga.value as FormArray;
        //     for (let i=0;i<bultos.length;i++){
        //         let id_item = bultos.at(i).get('id_item')?.value;
        //         this.api.getItem(id_item).subscribe( data => {
        //             bultos.at(i).get('descripcion')?.patchValue(data[0]['nombre']);
        //             (this.formulario_carga.get('bulto') as FormArray).at(i).get('descripcion')?.patchValue(data[0]['nombre']);
        //         });
        //     }
            console.log('guarda encomienda');
            this.api.guardarCarga(this.formulario_carga.value);
            console.log('encomienda guardada');
            // this.route.navigate(['/cargas/cruces/resumen']);
		// }
    }

	get campos_carga(){
		return this.formulario_carga.get('campos_carga') as FormArray;
	}

    setNombreTramo(nombre_tramo: string){
        nombre_tramo = nombre_tramo.replace('>','');
        this.formulario_carga.controls['nombre_tramo'].setValue(nombre_tramo);
    }

    private _filtro(nombre: string): Item[] {
        const filterValue = nombre.toLowerCase();
        return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }

    volver(id_cruce: number){
        this.route.navigate(['/cargas/cruces/lista_envios'],{state: { id_cruce: id_cruce }});
    }
}

@Component({
    selector: 'tabla',
    templateUrl: 'tabla.html'
})

export class DialogTabla {
    /*constructor(public dialogRef: MatDialogRef<DialogTabla>,
                @Inject(MAT_DIALOG_DATA)
                public data: DialogData,
                private fb: FormBuilder){
        console.log(data);
        campos: this.fb.array([
            this.fb.group(new fila_tabla())
        ])
    }

    onNoClick(): void {
        this.dialogRef.close();
    }*/
}