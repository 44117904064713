import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

//import { Moment } from 'moment';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

import Swal from 'sweetalert2';
import { Navigation } from 'selenium-webdriver';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-nagan',
    templateUrl: './nagan.component.html',
    styleUrls: ['./nagan.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS
        }
    ]
})


export class NaganComponent implements OnInit {

    formulario: FormGroup;
    mes = new FormControl();
    date = new FormControl();
    date2 = new FormControl(moment());
    dp = new FormControl();

    

    @Output() nombre: string = "Manul";

    constructor(private router: Router,private api: ApiService) {
        this.formulario = new FormGroup({
            dpInput: new FormControl('')
        });
    }
    usuario = sessionStorage.getItem('usuario')
    
    ngOnInit(): void {
    }

    monthSelected(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, mes_cruce:any) {
        
        datepicker.close();
        mes_cruce.value = normalizedMonth.toISOString().split('-').join('/').substr(0, 7);
        const year = mes_cruce.value.substr(0,4)
        const month = mes_cruce.value.substr(1-3)
        console.log(year+'-'+month)
        const fecha =  year+'-'+month
        const url = 'cruces/lista_cargas'
        this.cargar_viajes(url, fecha);
    }

    cargar_contenido(url: string, fecha: string){
        if (fecha==''){
            Swal.fire({
                title: 'Atención',
                text: 'Escoger fecha'
            });
        }else{
            this.router.navigate(['/cargas/'+url],
            {state: { fecha: this.formulario.controls['mes_cruce'].value.year()+'-'+(this.formulario.controls['mes_cruce'].value.month()+1) }});
        }

    }

    cargar_viajes(url: string, fecha: string = ''){

        if (fecha==''){
            Swal.fire({
                title: 'Atención',
                text: 'Escoger fecha'
            });
        }
        else if(this.router.url == '/cargas/cruces'){
            this.router.navigate(['/cargas/'+url],
                {state: { fecha: fecha }});
        }
        else{
            this.router.navigate(['/cargas/cruces']).then(() =>
                this.router.navigate(['/cargas/'+url],
                {state: { fecha: fecha }})
            );

        }
    }

    salir(){
        this.api.logout();
        this.router.navigate(['login']);
    }

    comprobantes(){
        this.router.navigate(['/buscar']);
    }

    agregar_persona(){
        this.router.navigate(['/agregar_persona']);
    }

    // no se usa ya que por ahora no se escoge año
    chosenYearHandler(normalizedYear: Moment, mes: string='') {
        const ctrlValue = mes;
        console.log(mes)
        //ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    // no funciona como debe
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        //const ctrlValue = this.date.value;
        //const ctrlValue = this.formulario.controls['mes_cruce'].value;
        //ctrlValue.month(normalizedMonth.month());
        this.formulario.controls['mes_cruce'].setValue(normalizedMonth.month()+'/'+normalizedMonth.year());
        //datepicker.close();
    }

}
