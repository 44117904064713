import { Injectable } from '@angular/core';
import { LoginI } from '../models/login.interface';
import { ResponseI } from '../models/response.interface';
import { TicketI } from '../models/ticket.interface';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Icruce } from 'src/app/models/cruce.interface';
import { Iencomiendas } from '../models/encomiendas.interface';
import { Options } from 'selenium-webdriver';
import { Router } from '@angular/router';
import { ComprobanteI } from '../models/comprobante.interface';

//import { UsuarioModel } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	origen: string = 'https://api-carga.tabsa.cl/api/';
	url: string = this.origen+'auth';
	// url_tramo: string = this.origen+'tramos';
	url_tramo: string = 'https://api-cruce.tabsa.cl/api/tramos';
	datos_personales: string = this.origen+'persona';
	userToken: any = '';
	public visible: boolean;

    // APIS
    url_carga: string = 'https://api-carga.tabsa.cl/api/';
	url_persona: string = 'https://api-cliente.tabsa.cl/api/';
    encomienda: string='encomienda';
    url_guardar_encomienda = "https://api-carga.tabsa.cl/api/encomienda/guardar";

    // al final año y mes
    url_cruces_mes: string = 'https://api-cruce.tabsa.cl/api/cruce/fecha/mes/';

    url_estado_encomienda: string = 'https://api-carga.tabsa.cl/api/encomienda/estado';

    // para obtener las encomiendas por cruce, al final va el id de cruce
    url_encomiendas_cruce: string = 'https://api-carga.tabsa.cl/api/encomienda/cruce/';

    // lista de ítems
    url_items: string = 'https://api-carga.tabsa.cl/api/item';

	// Endpoint Tickets
	url_ticket: string = 'https://api-carga.tabsa.cl/api/bulto/encomienda/';
	url_comprobante: string = 'https://api-carga.tabsa.cl/api/bulto/encomienda/comprobante/';

    url_guardar_personas = this.url_persona+'persona/multi/store'



	constructor(private http: HttpClient, private route: Router ) {
		// this.userToken='';
		this.visible = false;
	}

    getItem(id_item: number){
        return this.http.get<ResponseI>(this.url_items+'/'+id_item).pipe(
            map(
                (resp: any) => {
                    return resp;
                }
            )
        );
    }
    getItems(){
        return this.http.get<ResponseI>(this.url_items).pipe(
            map((resp: any) => {
                return resp;
            })
        );

    }

    getEncomienda(encomienda: string){
		console.log(this.origen+'encomienda/'+encomienda)
        return this.http.get<ResponseI>(this.origen+'encomienda/'+encomienda).pipe(
            map(
                (resp: any) => {
                    return resp;
                }
            )
        );
    }

    formatear_rut(rut: string, separa_mil: boolean){
        var aux: string = '';
        var partes=[];
        var num: number;
        rut = rut.replace(/[.-]/g,'')
        if (rut.search('-')==-1 && rut.length>1){
            aux = rut.slice(-1);
            rut = rut.slice(0,rut.length-1)+'-'+aux;
        }
        if (separa_mil){
            console.log('separa_mil')
            console.log(separa_mil)
            partes = rut.split('-');
            num = +partes[0];
            aux = new Intl.NumberFormat().format(num);
            console.log('aux')
            console.log(aux)
            console.log('partes')
            console.log(partes)
            rut = partes[0]+'-'+partes[1];
        }
        return rut;
    }

    getEncomiendasCruce(id_cruce: number){
		console.log(this.url_encomiendas_cruce+id_cruce)
        return this.http.get<ResponseI>(this.url_encomiendas_cruce+id_cruce+'/20').pipe(
            map(
                (resp: any) => {
                    return resp;
                }
            )
        );
    }

    getEncomiendasCruceFull(id_cruce: number){
		console.log(this.url_encomiendas_cruce+id_cruce)
        return this.http.get<ResponseI>(this.url_encomiendas_cruce+id_cruce).pipe(
            map(
                (resp: any) => {
                    return resp;
                }
            )
        );
    }

    getCruces(fecha: string){
        return this.http.get<any>(this.url_cruces_mes+fecha).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);
    }

    getCrucesCambiar(){
        return this.http.get<any>('https://api-cruce.tabsa.cl/api/cruces/tramo/cargas/3').pipe(
            map(
                (resp: any) => {
                    console.log('cruces obtenidos')
                    console.log(resp)
                    return resp
                }
            )
		);
    }

    asignarNuevoCruce(payload: any){
        console.log('data nuevo cruce')
        console.log(payload)
        return this.http.post('https://api-carga.tabsa.cl/api/encomienda/resignar/cruce',payload).pipe(
            map(
                (resp: any) => {
                    console.log('respuestowski')
                    console.log(resp)
                    return resp
                }
            )
		);

    }

    getComprobante(id_encomienda: number){
        return this.http.get<ComprobanteI[]>(this.url_comprobante+id_encomienda)
    }

	getTickets(id_encomienda: number){
        return this.http.get<TicketI[]>(this.url_ticket+id_encomienda)
    }

	getTicket(id_bulto: number){
        return this.http.get<TicketI[]>(this.url_ticket+"individual/"+id_bulto)
    }

	login(user: LoginI): Observable<ResponseI>{
		const authData = {
		...user,
		returnSecureToken: true
		};
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        return this.http.post<ResponseI>(this.url,authData,{'headers':headers}).pipe(
            map(
                (resp: any) => {
                    console.log(resp)
                    if(resp['token'] != null){
                        console.log('entra');
                        this.guardarToken(resp['token'],resp['user'],resp['nombre'],resp['id']);
                        return resp
                    }else{
                        alert(`Error de ${resp['error']}.`)
                    }
                }
            )
		);


		// return this.http.post<ResponseI>(this.url,authData).pipe(
        //     map(
        //         (resp: any) => {
        //             this.guardarToken(resp['token'],resp['nombre']);
        //             return resp
        //         }
        //     )
		// );
	}

	public guardarToken(idToken: string, user: string, nombre:string, id:string){
		this.userToken = idToken;
		sessionStorage.setItem('token',this.userToken);
		sessionStorage.setItem('usuario',user);
		sessionStorage.setItem('nombre',nombre);
		sessionStorage.setItem('id',id);
		// expiracion token
		let hoy = new Date();
		hoy.setSeconds(3600);
		sessionStorage.setItem('expira', hoy.getTime().toString() );
	}

	leerToken(){
		if (sessionStorage.getItem('token')){
		    this.userToken = sessionStorage.getItem('token');
		}else{
            console.log('remuevo token');
		    this.userToken = '';
		}
		return this.userToken;
	}

	estaAutenticado(): boolean{
		if (this.userToken === null){
            console.log('');
		    return false;
		}else{
            console.log(this.userToken)
            const expiracion = Number(sessionStorage.getItem('expira'));
            const expiraDate = new Date();
            expiraDate.setTime(expiracion);
            if (expiraDate > new Date()){
                return true;
            }else{
                this.userToken = null;
                return false;
            }
        }
	}

	logout(){
		this.userToken = null;
        console.log(this.userToken)
		sessionStorage.removeItem('token');
	}

	usuario(){
		if (this.estaAutenticado())
		return sessionStorage.getItem('id');
		return '';
	}

	tramos(data: any){
		const datos = {
		  id_tramo: data.id_tramo
		}
		return this.http.get<ResponseI>(this.url_tramo+'/'+3).pipe(
			map(
				(resp: any) => {
					return resp
				}
			)
		);
	}

	hide() { this.visible = false; }

	show() {
		this.visible = true;
	}

	toggle() { this.visible = !this.visible; }

	getDatosPersonales(rut: String){
		const datos = {
		  rut: rut
		}
		return this.http.post<ResponseI>(this.datos_personales,datos).pipe(
			map(
				(resp: any) => {
					return resp
				}
			)
		);
	}

    //return this.http.delete<ResponseI>(id_bulto)
    guardarCarga(formulario: any){
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        var datos = {
            id_usuario_ad : formulario.id_usuario_ad,
            id_lote : formulario.id_lote,
            // id_detalle_pago : formulario.id_detalle_pago,
            usuario : formulario.usuario_desc,
            valor : formulario.valor,
            volumen : formulario.volumen,
            id_persona_envia : formulario.id_persona_envia,
            rut_persona_envia : formulario.rut_cargador,
            pasaporte_persona_envia : formulario.pasaporte_cargador,
            nombre_persona_envia : formulario.nombre_cargador,
            apellido_persona_envia : formulario.apellido_cargador,
            telefono_persona_envia : formulario.telefono_cargador,
            email_persona_envia : formulario.email_cargador,
            id_persona_recibe : formulario.id_persona_recibe,
            rut_persona_recibe : formulario.rut_destinatario,
            pasaporte_persona_recibe : formulario.pasaporte_destinatario,
            nombre_persona_recibe : formulario.nombre_destinatario,
            apellido_persona_recibe : formulario.apellido_destinatario,
            telefono_persona_recibe : formulario.telefono_destinatario,
            email_persona_recibe : formulario.email_destinatario,
            id_cruce : formulario.id_cruce,
            id_item: formulario.item,
            numero_lote : formulario.numero_lote,
            // cantidad: formulario.cantidad,
            bulto : formulario.bulto
        }
        console.log(datos);
        sessionStorage.setItem('carga',JSON.stringify(formulario));

        //Así estaba antes

		// this.http.post<ResponseI>(this.url_guardar_encomienda,datos,{'headers':headers}).subscribe(
        //     (response) => console.log(response),
        //     (error) => console.log(error)
        //   )


        //Así recarga con la encomienda ya insertada

        const prueba = this.http.post<ResponseI>(this.url_guardar_encomienda,datos,{'headers':headers}).subscribe(
            (response) => console.log(response),
            (error) => console.log(error)
        )
        this.route.navigate(['/cargas/cruces/lista_envios'],{state: { id_cruce: datos.id_cruce }});
        
        return prueba;
    }

    leerCarga(){
        if (this.estaAutenticado()){
            return sessionStorage.getItem('carga');
        }
        return "nada";
    }

    eliminarBulto(id : number){
		const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        return this.http.delete<ResponseI>(this.url_carga + 'bulto/' + id,{'headers':headers}).subscribe(
            (response) => console.log(response),
            (error) => console.log(error),
          )
    }

    // getComprobante( encomienda : number ){
    //     const headers = new HttpHeaders({
    //         'Content-Type':'application/json'
    //     });
    //     // const body = { boleta : encomienda };
    //     console.log(encomienda);
    //     return this.http.post<ResponseI>(this.url_carga + 'comprobante/'+encomienda,{'headers':headers}).pipe(
    //         map(
    //             (resp: any) => {
    //                 return resp
    //             }
    //         )
	// 	);
    // }

    getPersonaByRut( rutPersona : string ){
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        const body = { rut : rutPersona };
        console.log(body);
        return this.http.post<ResponseI>(this.url_persona + 'persona/rut',body,{'headers':headers}).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);
    }
    getPersonaByPasaporte( pasaportePersona : string ){
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        const body = { pasaporte : pasaportePersona };
        console.log('entra a gpbpasaporte');
        console.log(body);
        return this.http.post<ResponseI>(this.url_persona + 'persona/pasaporte',body,{'headers':headers}).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);
    }

    postPersona( persona : any ){
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        const body = { rut : persona.rut, pasaporte : persona.pasaporte, nombre: persona.nombre, apellido : persona.apellido, email : persona.email, telefono : persona.telefono };
        console.log("persona:"+ JSON.stringify(body));
        return this.http.post<ResponseI>(this.url_carga + 'persona/carga',body,{'headers':headers}).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);
    }

	getManifiesto (id_cruce: number ){
		return this.http.get<ResponseI>(this.url_carga + 'manifiesto/' + id_cruce).pipe(
			map(
				(resp: any) => {
					return resp
				}
			)
		);
	}	

    postMultiPersonas(formulario:any){
        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        var datos = {
            persona : formulario.persona
            
        }
        console.log(datos)
        sessionStorage.setItem('personas',JSON.stringify(formulario))

        const resp = this.http.post<ResponseI>(this.url_guardar_personas,datos,{'headers':headers}).subscribe(
            (response) => console.log(response),
            (error) => console.log(error)
        )
        this.route.navigate(['/cargas/cruces']);
        
        return resp;

    }

    getEstadoEncomienda(encomienda:any){

        const headers = new HttpHeaders({
            'Content-Type':'application/json'
        });
        var datos = {
            id_encomienda : encomienda.id_encomienda,
            rut : encomienda.rut
        }

        console.log(`rut = ${datos}`)
        return this.http.post<ResponseI>(this.url_estado_encomienda,encomienda,{'headers':headers}).pipe(
            map(
                (resp: any)=>{
                    return resp;
                }
            )
        )
        this.route.navigate(['/encomienda/resultado']);


        // console.log(this.origen+'encomienda/'+encomienda)
        // return this.http.get<ResponseI>(this.origen+'encomienda/'+encomienda).pipe(
        //     map(
        //         (resp: any) => {
        //             return resp;
        //         }
        //     )
        // );
        
        // return resp;
    }

    bultosEstado(id_cruce: any, estado: any){

        return this.http.get<any>(this.origen+'bulto/estado/'+id_cruce+'/'+estado).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);

    }

    cambiarEstado(id_qr: any){

        return this.http.get<any>(this.origen+'bulto/nuevo_estado/'+id_qr).pipe(
            map(
                (resp: any) => {
                    return resp
                }
            )
		);

    }

}


