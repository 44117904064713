<h1 mat-dialog-title>
</h1>

<mat-dialog-content>
<mat-card>
    <mat-card-content >
        <button class="btn btn-primary" 
        [useExistingCss]="true"
        styleSheetFile="node_modules/bootstrap/dist/css/bootstrap.min.css" 
        printSectionId="print-section" ngxPrint><mat-icon>print</mat-icon></button> 
        <body style="width:auto  ;height: auto;" id="print-section">
            
            <div id = "demo">

     <table align="center" *ngFor="let b of bultos"> 
                <tbody >
                    <tr>
                        <td colspan="5" style="font-weight: bold; font-size: 10.8px; text-align: left;">{{b.persona_recibe[0].nombre +' '+ b.persona_recibe[0].apellido | uppercase}}</td>
                    </tr>
                    
                    <tr align="center">
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="5">{{b.persona_recibe[0].rut}}&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="3">{{b.persona_recibe[0].email}}&nbsp;</td>
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].telefono}}&nbsp;</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="3">{{b.persona_recibe[0].residencia_direccion}}&nbsp;</td>
                        <!-- <td style="font-size:22px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].residencia_ciudad}}</td> -->
                    </tr>
                    <tr align="center">
                        <td style="font-size:5.3px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="font-weight: bold; font-size: 10.8px; text-align: left;">{{b.persona_envia[0].nombre +' '+ b.persona_envia[0].apellido | uppercase}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="5">{{b.persona_envia[0].rut}}&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="4">{{b.persona_envia[0].email}}&nbsp;</td>
                        <td style="font-size:9px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_envia[0].telefono}}&nbsp;</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:5.3px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <td style="font-size:8.1px; font-weight: bold; text-align: left;" colspan="4">Rec. {{b.recepcionado}}</td>
                        <td colspan="1" style="font-weight: bold; font-size: 8.1px; text-align: left;">{{b.posicion}} / {{b.total}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:8.1px; font-weight: bold; text-align: left;" colspan="3">{{b.nombre}}</td>
                        <!-- <td style="font-size:20px; font-weight: bold; text-align: left;" colspan="2">{{b.volumen}}</td> -->
                    </tr>
                    <tr align="center">
                    </tr>
                    <tr>
                        <td style="font-size:8.1; font-weight: bold;" colspan="5">{{b.desc}}</td>
                    </tr>
                    <tr>
                    <td style="text-align: center; width: 140 !important; height: 140 !important;" colspan="5"><img src="{{b.image}}" alt=""></td>
                </tr>
                <tr>
                    <td style="font-size:9px; font-weight: bold;" colspan="3">Origen: {{b.origen}}</td>
                    <td colspan="2" style="font-size: 9px; font-weight: bold;text-align: right;">ID {{b.id_qr}}</td>
                </tr>
                <tr>
                    <td style="font-size:9px; font-weight: bold;" colspan="3">Destino: {{b.destino}}</td>
                    <td colspan="2" style="font-size: 9px; font-weight: bold;text-align: right;">LOTE {{b.numero_lote}}</td>
                </tr>
                <!-- <tr align="center">
                    <td style="font-size:14px; font-weight: bold;" colspan="5">________________________________________________________</td>
                </tr> -->
                <tr>
                    <td align="center" colspan = "5"style="font-size: 7.1px; font-weight: bold">TABSA; RUT: 82.074.900-6 — Juan Williams 06450</td>
                </tr>
                <tr>
                    <td align="center" colspan = "5"style="font-size: 7.1px; font-weight: bold">Tel: +(56) (61) 2728100</td>
                </tr>
            </tbody>
        </table>











            <!-- <table align="center" *ngFor="let b of bultos"> 
                <tbody >
                    <tr>
                        <td colspan="5" style="font-weight: bold; font-size: 18px; text-align: left;">{{b.persona_recibe[0].nombre}} {{b.persona_recibe[0].apellido}}</td>
                    </tr>
                    
                    <tr align="center">
                        <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].email}}</td>
                        <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].telefono}}</td>
                        <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="1">{{b.persona_recibe[0].rut}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="3">{{b.persona_recibe[0].residencia_direccion}}</td>
                        <td style="font-size:16px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_recibe[0].residencia_ciudad}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="font-weight: bold; font-size: 14px; text-align: left;">{{b.persona_envia[0].nombre}} {{b.persona_envia[0].apellido}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_envia[0].email}}</td>
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.persona_envia[0].telefono}}</td>
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="1">{{b.persona_envia[0].rut}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="font-weight: bold; font-size: 14px; text-align: left;">{{b.posicion}} / {{b.total}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">Rec. {{b.recepcionado}}</td>
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="2">{{b.nombre}}</td>
                        <td style="font-size:13px; font-weight: bold; text-align: left;" colspan="1">{{b.peso}}</td>
                    </tr>
                    <tr align="center">
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight: bold;" colspan="5">{{b.desc}}</td>
                    </tr>
                    <tr>
                    <td style="text-align: center;" colspan="5"><img src="{{b.image}}" alt=""></td>
                </tr>
                <tr>
                    <td style="font-size:14px; font-weight: bold;" colspan="3">Origen: {{b.origen}}</td>
                    <td rowspan = "2" colspan="2" style="font-size: 40px; font-weight: bold;text-align: right;">LOTE {{b.numero_lote}}</td>
                </tr>
                <tr>
                    <td style="font-size:124x; font-weight: bold;" colspan="2">Destino: {{b.destino}}</td>
                </tr>
                <tr align="center">
                    <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                </tr>
                <tr>
                    <td align="center" colspan = "5"style="font-size: 10px; font-weight: bold">TABSA; RUT: 82.074.900-6 — Juan Williams 06450; tel: +(56) (61) 2728100</td>
                </tr>
            </tbody>
        </table> -->
    </div>
</body>
    </mat-card-content>
</mat-card>
</mat-dialog-content>