import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { IEstadoEncomienda } from 'src/app/models/estado-encomienda.interface';
import { EncomiendaComponent } from '../encomienda/encomienda.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table'
import Swal from 'sweetalert2';


@Component({
  selector: 'app-encomienda-resultado',
  templateUrl: './encomienda-resultado.component.html',
  styleUrls: ['./encomienda-resultado.component.css']
})
export class EncomiendaResultadoComponent implements OnInit {

  // id_encomienda   : string = '';
  // rut   : string = '';

  encomienda   : string = '';
  encomiendas! : IEstadoEncomienda[];
  columnas: string[] = ['item', 'estado'];
  constructor(private router: Router,private api: ApiService, private dialog: MatDialog) {

    console.log("encomienda estado: ")

      this.encomienda = this.router.getCurrentNavigation()?.extras.state?.encomienda;
  }

  ngOnInit(): void {
    this.api.getEstadoEncomienda(this.encomienda).subscribe( data => {
      this.encomiendas! = data.data;
      console.log(this.encomiendas)
      console.log(`estado encomiendas: ${JSON.stringify(this.encomiendas)}`)
      if(data.data == null){
        Swal.fire({
          title: 'No hay resultados',
          text: `No se ha encontrado resultado para encomienda Nº${data.encomienda} con destinatario rut ${data.rut}`
        })
      }
    });
  }

}

