import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComprobanteI } from 'src/app/models/comprobante.interface';

@Component({
  selector: 'app-comprobante',
  templateUrl: './comprobante.component.html',
  styleUrls: ['./comprobante.component.css']
})
export class ComprobanteComponent implements OnInit {

  encomiendas: any;
  id_cruce: number;
  bultos! : ComprobanteI[];
  prueba! : any[];
  usuario = sessionStorage.getItem('nombre')

  constructor(public dialogRef: MatDialogRef<ComprobanteComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any | ComprobanteI, private router: Router, private api: ApiService,public dialog: MatDialog) {
          console.log(data);
          console.log(`id encomienda ${data.id_encomienda}`);
          
          this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
          this.api.getComprobante(data.id_encomienda).subscribe(
            data => {
              this.bultos = data;
              this.prueba = this.bultos;
              }
            );
            console.log(`resultado: ${JSON.stringify(this.data)}`)
      }
      
      printPage() {
        window.print();
      }
  ngOnInit(): void {
  }



}
