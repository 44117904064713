import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table'
import { Iencomiendas } from 'src/app/models/encomiendas.interface';
import { ComprobanteComponent } from '../../comprobante/comprobante.component';
import { ComprobanteEntregaComponent } from '../../comprobante-entrega/comprobante-entrega.component';

@Component({
  selector: 'app-modulo-comprobantes',
  templateUrl: './modulo-comprobantes.component.html',
  styleUrls: ['./modulo-comprobantes.component.css']
})
export class ModuloComprobantesComponent implements OnInit {
 
  encomienda   : string = '';
  encomiendas! : Iencomiendas[];
  columnas: string[] = ['persona_envia', 'persona_recibe','imprimir_comprobante_r','imprimir_comprobante_e'];
  constructor(private router: Router,private api: ApiService, private dialog: MatDialog) {
      console.log("carga lista")
      this.encomienda = this.router.getCurrentNavigation()?.extras.state?.encomienda;
  }

  ngOnInit(): void {
      this.api.getEncomienda(this.encomienda).subscribe( data => {
          this.encomiendas = data.data;
          console.log(`data encomiendas estado: ${this.encomiendas}`)
      });
      console.log(this.encomiendas)
  }

  impresionComprobante(bultos: any, id_encomienda: number): void {
    const dialogRef = this.dialog.open(ComprobanteComponent,{
        width: '550px',
        data: {bultos: bultos, id_encomienda: id_encomienda}
    });
    dialogRef.afterClosed().subscribe( resp => {
        console.log(resp);
    });
}

impresionComprobanteEntrega(bultos: any, id_encomienda: number): void {
    const dialogRef = this.dialog.open(ComprobanteEntregaComponent,{
        width: '550px',
        data: {bultos: bultos, id_encomienda: id_encomienda}
    });
    dialogRef.afterClosed().subscribe( resp => {
        console.log(resp);
    });
}

}
