<h1 mat-dialog-title>
    Encomienda {{ data.id_encomienda }}
</h1>
<mat-dialog-content>
<mat-card>
    <mat-card-content>
        <table>
            <thead>
            <tr>
                <th width='20%' align="center">Nº Bulto</th>
                <th>Nombre del ítem</th>
                <th width="20%">Imprimir</th>
                <th width='20%'>Quitar</th>
            </tr>
            </thead>
            <tbody *ngFor="let bulto of data.bultos">
                <tr [ngStyle]="{'background-color':bulto.id_estado == 4 ? 'green' : '' }">
                    <td align="center">{{bulto.posicion}}</td>
                    <td>{{ bulto.item.nombre }}</td>
                    <td align="center">
                        <mat-button-toggle (click)="impresion(bulto.id_qr,bulto.id_encomienda)"><mat-icon>print</mat-icon></mat-button-toggle></td>
                    <td align="center"><mat-button-toggle (click)="eliminar(bulto.id_qr)"><mat-icon>delete_forever</mat-icon></mat-button-toggle></td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
</mat-dialog-content>
