
    <table>
        <thead>
            <tr>
                <th width="4%">#</th>
                <th width="10%">N° de bultos</th>
                <th width="10%">Peso</th>
                <th width="10%">Largo</th>
                <th width="10%">Volumen</th>
                <th>Contenido declarado</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>
                    <input
                        type='number'
                        min='0'
                        autocomplete="off"
                        style="width: 50px;">
                </td>
                <td>
                    <input
                        type='number'
                        min="0" step=".1"
                        autocomplete="off"
                        style="width: 50px;">
                </td>
                <td>
                    <input
                        type='number'
                        min="0" step=".1"
                        autocomplete="off"
                        style="width: 50px;">
                </td>
                <td>
                    <input
                        type='number'
                        min="0" step=".1"
                        autocomplete="off"
                        style="width: 50px;">
                </td>
                <td>
                    <textarea
                        matInput
                        autocomplete='off'
                        ></textarea>
                </td>
            </tr>
        </tbody>
    </table>


    <div mat-dialog-actions>
        <!--<button mat-button (click)="onNoClick()">No Thanks</button>-->
    </div>
