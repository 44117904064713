<mat-card align="center" >
	<mat-card-title>Ingreso</mat-card-title>
	<mat-card-content>
		<form
      (ngSubmit)="onLogin(loginForm.value)"
      name='loginForm'
      [formGroup]='loginForm'
      autocomplete="off"
      >
			<mat-form-field class="example-full-width" appearance="fill">
				<mat-label>Usuario</mat-label>
				<input matInput
				formControlName="user"
				required>
				<mat-icon matSuffix>
					assignment_ind
				</mat-icon>
				</mat-form-field><br>
				<mat-form-field class="example-full-width" appearance="fill">
					<mat-label>Contraseña</mat-label>
					<input matInput
					type="password"
					formControlName="password"
					required
					[type]="showPassword ? 'text' : 'password'" name="password">
				<mat-icon matSuffix (click)="togglePasswordVisibility()">
					{{showPassword?'visibility_off':'visibility'}}
				</mat-icon>
			</mat-form-field>

			<section>
				<button mat-raised-button type="submit">Ingresar</button>
			</section>
		</form>
	</mat-card-content>
</mat-card>
<!-- <div align="center">
	<img src="../../../assets/sdc.png" alt="" style="width: 70vw;">
</div> -->
