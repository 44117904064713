
<mat-card>


    <mat-card-title *ngIf="estado == 1">Bultos con Estado RECIBIDO</mat-card-title>
    <mat-card-title *ngIf="estado == 2">Bultos con Estado PREPARADO PARA ENVÍO</mat-card-title>
    <mat-card-title *ngIf="estado == 3">Bultos con Estado EN VIAJE</mat-card-title>
    <mat-card-title *ngIf="estado == 4">Bultos con Estado ARRIBADO</mat-card-title>
    <mat-card-title *ngIf="estado == 5">Bultos con Estado PREPARADO PARA ENTREGA</mat-card-title>
    <mat-card-title *ngIf="estado == 6">Bultos con Estado ENTREGADO</mat-card-title>
    <mat-card-title *ngIf="estado == 7">Bultos con Estado EXTRAVIADO</mat-card-title>

    <mat-card-actions>
        <button mat-raised-button (click)="volver(id_cruce)" style="margin-left: 10px;">
            <mat-icon>keyboard_backspace</mat-icon> Volver
        </button>
    </mat-card-actions>

    <mat-card-content >
        <br><br>
        <div class="mat-elevation-z8">
        <mat-table [dataSource]="bultos">

            <!-- columna ID -->
            <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID QR </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="ID"> {{element.id_qr}} </mat-cell>
            </ng-container>

            <!-- columna TRAMO -->
            <ng-container matColumnDef="id encomienda">
                <mat-header-cell *matHeaderCellDef> ID ENCOMIENDA </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="ID_ENCOMIMENDA"> {{id_cruce}}{{element.bulto.id_encomienda}} </mat-cell>
            </ng-container>

            <!-- columna HORARIO -->
            <ng-container matColumnDef="item">
            <mat-header-cell *matHeaderCellDef> ÍTEM </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="ITEM"> {{element.bulto.item.nombre}} </mat-cell>
            </ng-container>

            <!-- columna ABRIR -->
            <ng-container matColumnDef="lote">
                <mat-header-cell *matHeaderCellDef> LOTE </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="LOTE"> {{element.bulto.encomienda.lote.numero_lote}} </mat-cell>
                </ng-container>

            <ng-container matColumnDef="ticket">
                <mat-header-cell *matHeaderCellDef> TICKET </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="TICKET"> 
                    <mat-button-toggle (click)="impresion(element.id_qr,element.bulto.id_encomienda)"><mat-icon>print</mat-icon></mat-button-toggle>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fecha registro">
                <mat-header-cell *matHeaderCellDef> FECHA REGISTRO </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="FECHA_REGISTRO"> {{element.registrado}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ingresado por">
                <mat-header-cell *matHeaderCellDef> INGRESADO POR </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="INGRESADO_POR"> {{element.bulto.descripcion}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cambiar estado" >
                <mat-header-cell *matHeaderCellDef> CAMBIAR ESTADO (+1)</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="CAMBIAR_ESTADO" style="justify-content: center;">
                    <mat-button-toggle *ngIf="(estado == 2 || estado == 3 || estado == 6) || usuario == false" disabled="true"><mat-icon>swap_horiz</mat-icon></mat-button-toggle>
                    <mat-button-toggle *ngIf="(estado == 1 || estado == 4 || estado == 5) && usuario == true" (click)="cambiarEstado(element.id_qr)"><mat-icon>swap_horiz</mat-icon></mat-button-toggle>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cambiar cruce" >
                <mat-header-cell *matHeaderCellDef> CAMBIAR CRUCE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="CAMBIAR_CRUCE" style="justify-content: center;">
                    <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="selectedBultos(element.bulto.id_encomienda)"
                    [checked]="sbultos.includes(element.bulto.id_encomienda) ? true : false">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="sbultos.length > 0 && usuario == true">
        <br>
        <button mat-raised-button (click)="cambiarCruce()">
            Cambiar Cruce
        </button>
    </div>
    </mat-card-content>
</mat-card>
