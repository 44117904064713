import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { DetallecargaComponent } from '../detallecarga/detallecarga.component';
import { ImpresionComponent } from '../../impresion/impresion.component';
import { ComprobanteComponent } from '../../comprobante/comprobante.component';
import { ComprobanteEntregaComponent } from '../../comprobante-entrega/comprobante-entrega.component';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Pipe, PipeTransform } from '@angular/core';
import { AppComponent } from '../../../app.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-lista-envios',
  templateUrl: './lista-envios.component.html',
  styleUrls: ['./lista-envios.component.css'],
})
export class ListaEnviosComponent implements OnInit {
    id_cruce: number;
    encomiendas! : any[];
    suma_total :any;
    recibidos :any;
    enviaje :any;
    arribados :any;
    ppenvio :any;
    ppentrega :any;
    entregados :any;
    horario: any;
    dataSource! : MatTableDataSource<any>;
    rut: any;

    @ViewChild(MatSort) matSort!: MatSort
    columnas : string[] = ['id','lote','envia','recibe','descripcion','imprimir_ticket','para_envio','para_entrega','entregados','cantidad_bultos'];
    // columnas : string[] = ['id','lote','envia','recibe','descripcion','imprimir_ticket','imprimir_comprobante_r','imprimir_comprobante_e'];
    constructor(private router: Router, private api: ApiService,public dialog: MatDialog, private app: AppComponent) {
        this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
        this.horario = sessionStorage.getItem('horario')
        this.app.setLoading(true);
    //    this.suma_total =  this.dataSource
        // parche

        /*Swal.fire({
            title: 'Atención '+this.id_cruce,
            text: 'Escoger fecha'
        });*/
    }


    @ViewChild(MatSort) sort!: MatSort;


    // ngAfterViewInit(){
        
    // }

    ngOnInit(): void {

        console.log(this.id_cruce);
        this.api.getEncomiendasCruce(this.id_cruce).subscribe(
            data => {

                this.encomiendas = data.data;
                
                this.dataSource = new MatTableDataSource(this.encomiendas);
                
                console.log('Data Source = '+this.dataSource.data)
                if (this.dataSource.data == null) this.app.setLoading(false)
                // this.dataSource.sort = this.sort
                this.suma_total = data.cantidad[0]
                this.recibidos = data.recibidos
                this.enviaje = data.enviaje
                this.arribados = data.arribados
                this.ppenvio = data.ppenvio
                this.ppentrega = data.ppentrega
                // this.suma_total = JSON.stringify(data.cantidad[0])
                // this.suma_total = this.suma_total.substr(14,-3)
                this.entregados = data.entregados[0]
                this.dataSource.sort = this.matSort
                console.log('Cantidad = '+JSON.stringify(data.cantidad[0]))
                console.log('Entregados = '+JSON.stringify(this.suma_total))
                // console.log('Recibidos = '+JSON.stringify(data))
                console.log(`encomiendas : ${this.encomiendas}`);
                this.app.setLoading(false);
            }
            );
            
        console.log(this.dataSource);
    }

    applyFilter(filterValue: any){
        console.log('euqednks = '+filterValue.value)
        this.dataSource.filter = filterValue.value.trim().toLowerCase()
    }

    nueva_encomienda(id_cruce: number){
        this.router.navigate(['/cargas/cruces/nueva_encomienda'],{state: { id_cruce: id_cruce }});
    }

    manifiesto(id_cruce: number){
        this.router.navigate(['/cargas/cruces/manifiesto'],{state: { id_cruce: id_cruce }});
    }

    lista_full(id_cruce: number){
        this.router.navigate(['/cargas/cruces/lista_envios_full'],{state: { id_cruce: id_cruce }});
    }

    bultos_estado(id_cruce:any, estado:any){
        this.router.navigate(['/cargas/cruces/bultos-estado/'+id_cruce+'/'+estado],{state: { id_cruce: id_cruce }});
    }


    abrir_detalle(bultos: any, id_encomienda: number): void {
        const dialogRef = this.dialog.open(DetallecargaComponent,{
            width: '550px',
            data: {bultos: bultos, id_encomienda: id_encomienda}
        });
        dialogRef.afterClosed().subscribe( resp => {
            console.log(resp);
        });
    }

    // searchText(){

    // }
    

    filterData(event:any){
        console.log('euqednks = '+event.target.value)
        this.dataSource.filter = event.target.value
    }
    
    onChange($event:any){
        let filteredData
    }

    impresion(bultos: any, id_encomienda: number): void {
        const dialogRef = this.dialog.open(ImpresionComponent,{
            width: '550px',
            data: {bultos: bultos, id_encomienda: id_encomienda}
        });
        dialogRef.afterClosed().subscribe( resp => {
            console.log(resp);
        });
    }

    impresionComprobante(bultos: any, id_encomienda: number): void {
        const dialogRef = this.dialog.open(ComprobanteComponent,{
            width: '550px',
            data: {bultos: bultos, id_encomienda: id_encomienda}
        });
        dialogRef.afterClosed().subscribe( resp => {
            console.log(resp);
        });
    }

    impresionComprobanteEntrega(bultos: any, id_encomienda: number): void {
        const dialogRef = this.dialog.open(ComprobanteEntregaComponent,{
            width: '550px',
            data: {bultos: bultos, id_encomienda: id_encomienda}
        });
        dialogRef.afterClosed().subscribe( resp => {
            console.log(resp);
        });
    }

    refresh(id_cruce : number){
        console.log('entraaaa')
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl],{state: { id_cruce: sessionStorage.getItem('id_cruce') }});
            console.log(currentUrl);
        });
    }


}

