import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketI } from 'src/app/models/ticket.interface';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.css']
})
export class ImpresionComponent implements OnInit {

  encomiendas: any;
  id_cruce: number;
  bultos! : TicketI[];
  prueba! : any[];

  constructor(public dialogRef: MatDialogRef<ImpresionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any | TicketI, private router: Router, private api: ApiService,public dialog: MatDialog) {
          console.log(data);
          console.log(`id encomienda ${data.id_encomienda}`);
          
          this.id_cruce = this.router.getCurrentNavigation()?.extras.state?.id_cruce;
          this.api.getTickets(data.id_encomienda).subscribe(
            data => {
              this.bultos = data;
              this.prueba = this.bultos;
              }
            );
      }



      // constructor(public dialogRef: MatDialogRef<ImpresionComponent>,
      //   @Inject(MAT_DIALOG_DATA) public data: TicketI, private router: Router, private api: ApiService,public dialog: MatDialog) {
      //       console.log(data.id_encomienda);
      //       // this.prueba = "prueba";
      //      
      //   }
  

      printPage() {
        window.print();
      }
  ngOnInit(): void {
  }



}
