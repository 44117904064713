<div class="contenedor">
    <a [routerLink]="['../cargas/nagan']">
        <mat-card>
        <mat-card-title-group>
            <mat-card-title>Cargas</mat-card-title>
            <mat-card-subtitle>Manifiesto de carga y otros</mat-card-subtitle>
        </mat-card-title-group>
        </mat-card>
    </a>
    <a [routerLink]="['/behemoth']">
        <mat-card>
        <mat-card-title-group>
            <mat-card-title>Cajas</mat-card-title>
            <mat-card-subtitle>Trazado de recorrido de cajas</mat-card-subtitle>
        </mat-card-title-group>
        </mat-card>
    </a>
</div>
