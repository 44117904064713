<div class="contenedor">
    <a [routerLink]="['cruces']">
        <div class="submenu">
            <i class="fas fa-journal-whills"></i>
            Dash
        </div>
    </a>
    <!--<a [routerLink]="['dreadnought']">
        <div class="submenu">
            <i class="fas fa-jedi"></i>
            Manifiesto de carga
        </div>
    </a>-->
    <a [routerLink]="['reporte']">
        <div class="submenu">
            <i class="fab fa-galactic-republic"></i>
            Reportes
        </div>
    </a>
</div>
