import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
/*  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/

	constructor(private auth: ApiService, private router: Router){}

	canActivate(): boolean {
		if (this.auth.estaAutenticado()){
			return true;
		}else{
			this.router.navigateByUrl('/login');
            return false;
		}
	}
}
