
<!-- <mat-card>
    <mat-card-title>Cargas viaje de {{ fecha }}</mat-card-title>
    <mat-card-content>
        <table>
            <thead>
                <tr>
                    <th width="4%">#</th>
                    <th>id</th>
                    <th>Horario cruce</th>
                    <th width="10%">Abrir</th>
                    <th width="10%">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of cruces; let i=index;">
                    <td align='center'>{{ i+1 }}</td>
                    <td>{{ c.id }}</td>
                    <td>{{ c.presentacion | date }}</td>
                    <td align='center'><mat-icon (click)="envios_viaje('nagan/lista_envios',c.id)">input</mat-icon></td>
                    <td align='center'><mat-icon>delete</mat-icon></td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>

</mat-card> -->
    
<mat-card>
    <mat-card-title>Cruces {{ fecha }}</mat-card-title>

    <mat-card-content >
        <br><br>
        <div class="mat-elevation-z8">
        <mat-table [dataSource]="cruces">

            <!-- columna ID -->
            <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="ID" [ngStyle]="{'background-color':element.proximo == true ? 'green' : '' }"> {{element.id}} </mat-cell>
            </ng-container>

            <!-- columna TRAMO -->
            <ng-container matColumnDef="tramo">
                <mat-header-cell *matHeaderCellDef> TRAMO </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="TRAMO" [ngStyle]="{'background-color':element.proximo == true ? 'green' : '' }"> {{element.origen + ' - ' + element.destino}} </mat-cell>
            </ng-container>

            <!-- columna HORARIO -->
            <ng-container matColumnDef="horario cruce">
            <mat-header-cell *matHeaderCellDef> HORARIO CRUCE </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="HORARIO" [ngStyle]="{'background-color':element.proximo == true ? 'green' : '' }"> {{element.zarpeOriginal}} </mat-cell>
            </ng-container>

            <!-- columna ABRIR -->
            <ng-container matColumnDef="abrir">
                <mat-header-cell *matHeaderCellDef> ABRIR </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="ABRIR" [ngStyle]="{'background-color':element.proximo == true ? 'green' : '' }"> <mat-icon (click)="envios_viaje('cruces/lista_envios',element.id,element.zarpeOriginal)">input</mat-icon> </mat-cell>
            </ng-container>

            <!-- columna ELIMINAR
            <ng-container matColumnDef="eliminar">
                <mat-header-cell *matHeaderCellDef> ELIMINAR </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="ELIMINAR"> <mat-icon>delete</mat-icon> </mat-cell>
            </ng-container> -->


            <mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
        </mat-table>
        </div>
    </mat-card-content>
</mat-card>
