<mat-card  style="text-align: center !important;">
    <mat-card-title>
        Manifiesto de Carga
    </mat-card-title>
    <mat-card-actions>
        <button (click)="back(id_cruce)" mat-raised-button>
            <mat-icon>keyboard_backspace</mat-icon> Volver
        </button>
        <button class="btn btn-primary" mat-raised-button
        [useExistingCss]="true"
        styleSheetFile="node_modules/bootstrap/dist/css/bootstrap.min.css" 
        printSectionId="print-section" ngxPrint><mat-icon>print</mat-icon> Imprimir</button> 
        <button id="export" mat-raised-button style="background-color: #1D6F42; color: white;" 
        (click)="exporter.exportTable('xlsx', {fileName:'Manifiesto de Carga '+id_cruce})"><mat-icon>download</mat-icon> Excel</button>
    </mat-card-actions>

    <br>
    <mat-card-content>
        <div class="mat-elevation-z8" id="print-section" >
            <mat-table  [dataSource]="bultos" style="text-align:center" matTableExporter  #exporter="matTableExporter">
                <!-- columna ID -->
                <ng-container matColumnDef="encomienda">
                    <mat-header-cell *matHeaderCellDef>Nº ENCOMIENDA</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="encomienda"> {{id_cruce}}{{element.id_encomienda}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="observaciones">
                    <mat-header-cell *matHeaderCellDef>Nº LOTE</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="descripcion"> {{element.numero_lote}} </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="numero">
                  <mat-header-cell *matHeaderCellDef >N°</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="remitente" >
                  <mat-header-cell *matHeaderCellDef class="remitente">REMITENTE</mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="remitente"> {{element.remitente | uppercase}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="destinatario" >
                  <mat-header-cell *matHeaderCellDef class="destinatario">DESTINATARIO</mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="destinatario"> {{element.destinatario | uppercase}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nombre">
                    <mat-header-cell *matHeaderCellDef >DESCRIPCIÓN DE MERCADERÍA</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="nombre" style="text-align:left"> {{element.nombre}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="identificacion">
                    <mat-header-cell *matHeaderCellDef >IDENTIFICACIÓN</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="identificacion"> {{element.identificacion}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef >TOTAL (m³)</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="total"> {{element.volumen}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="cantidad">
                    <mat-header-cell *matHeaderCellDef >CANTIDAD</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="cantidad"> {{element.cantidad}} </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="lote">
                    <mat-header-cell *matHeaderCellDef >N° LOTE</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="lote"> {{element.numero_lote}} </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="residente_entrega">
                    <mat-header-cell *matHeaderCellDef >RESIDENTE</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="residente_entrega"> {{element.residente_entrega}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="residente_recibe">
                    <mat-header-cell *matHeaderCellDef >RESIDENTE</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="residente_recibe"> {{element.residente_recibe}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columnas" ></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
              </mat-table>
            </div>
        </mat-card-content>

</mat-card>
