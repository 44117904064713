<div class="dash_container" align="center">
    <mat-card>
        <mat-card-title>
            <p style="text-align: right; font-size: 14px;">
                <button mat-raised-button (click)="volver()" style="float: left;">
                    <i class="material-icons">keyboard_backspace</i> Volver
                </button>
                <button mat-raised-button (click)="salir()" style="margin-left: 10px;">
                    <mat-icon style="font: size 14px; color:red">exit_to_app</mat-icon> Salir
                </button>
            <br>Sesión iniciada como: {{usuario}}</p>Agregar Personas
        </mat-card-title>
    </mat-card>

    <form
    name='formulario_personas'
    [formGroup]='formulario_personas'
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)='guardar_formulario()'>

    <mat-card>
            <br><br>
            <table>
                <thead>
                    <th width="4%">#</th>
                    <th width="20%">RUT</th>
                    <th width="20%">Nombre</th>
                    <th width="20%">Apellido</th>
                    <th width="18%">Teléfono</th>
                    <th width="18%">Email</th>
                </thead>
                <tbody formArrayName="persona" align="center">
                    <tr *ngFor="let control of fila.controls; let i=index" [formGroupName]="i">
                        <td>{{ i+1 }}</td>
                        <td>
                            <input
                                autocomplete="off"
                                class="input_rut"
                                formControlName='rut'
                                style="width: 90%;"
                                placeholder="Ej: 11111111-1"
                                required>
                        </td>
                        <td>
                            <input
                                type='text'
                                autocomplete="off"
                                class="input_nombre"
                                style="width: 90%;"

                                formControlName='nombre' required>
                        </td>
                        <td>
                            <!-- matInput -->
                            <input type='text'
                                autocomplete='off'
                                style="width: 90%;"
                                class='input_apellido'
                                formControlName='apellido'>
                            <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(items)">
                                <mat-option *ngFor="let option of filteredOptions | async"
                                            [value]="option.id_item">
                                    {{ option.nombre }}
                                </mat-option>
                            </mat-autocomplete> -->
                        </td>
                        <td>
                            <!-- matInput -->
                            <input type='number'
                                autocomplete='off'
                                style="width: 90%;"
                                class='input_telefono'
                                formControlName='telefono'>
                        </td>
                        <td>
                            <input
                                type='text'
                                min="1" step="1"
                                autocomplete="off"
                                style="width: 90%;"
                                class="input_email"
                                formControlName='email'>
                        </td>
                        <td>
                            <button
                                mat-mini-fab
                                color="warn"
                                (click)="borrar_linea(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table><br>
            <button mat-stroked-button type='button' (click)="agregar_fila()"><i class="material-icons">add</i></button>

        </mat-card>


        <mat-card>
            <small><i>*TODOS LOS CAMPOS DEBEN SER RELLENADOS</i></small><br>
            <button mat-stroked-button>Guardar</button>
        </mat-card>

    <!--<app-lista-cargas [nombre]="nombre"></app-lista-cargas>-->
    </form>

    <router-outlet></router-outlet>

</div>
