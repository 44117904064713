import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Icruce } from 'src/app/models/cruce.interface';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-lista-cargas',
  templateUrl: './lista-cargas.component.html',
  styleUrls: ['./lista-cargas.component.css']
})
export class ListaCargasComponent implements OnInit {

    fecha   : string = '';
    cruces! : Icruce[];
    columnas: string[] = ['id', 'tramo', 'horario cruce', 'abrir'];
    constructor(private router: Router,private api: ApiService, private app: AppComponent) {
        console.log("carga lista")
        this.fecha = this.router.getCurrentNavigation()?.extras.state?.fecha;
        this.app.setLoading(true);
    }

    ngOnInit(): void {
        this.api.getCruces(this.fecha).subscribe( data => {
            this.cruces = data.data;
            console.log(this.cruces)
            this.app.setLoading(false);
        });
        console.log(this.cruces)
    }

    nueva_encomienda(url: string, fecha: string){
        this.router.navigate(['/cargas/'+url]);
    }

    envios_viaje(url: string, id_cruce: any, horario: any){
        sessionStorage.setItem('horario', horario)
		sessionStorage.setItem('id_cruce',id_cruce);
        this.router.navigate(['/cargas/'+url],{state: { id_cruce: id_cruce }});
    }
}
