<h1 mat-dialog-title>
</h1>

<mat-dialog-content>
<mat-card>
    <mat-card-content >
            <button class="btn btn-primary" 
            [useExistingCss]="true"
            styleSheetFile="node_modules/bootstrap/dist/css/bootstrap.min.css" 
            printSectionId="print-section" ngxPrint><mat-icon>print</mat-icon>TABSA</button> 

            <button class="btn btn-primary" style="margin-left:2vw;"
            [useExistingCss]="true"
            styleSheetFile="node_modules/bootstrap/dist/css/bootstrap.min.css" 
            printSectionId="print" ngxPrint><mat-icon>print</mat-icon>CLIENTE</button> 
            
        <body style="width:auto  ;height: auto;" >
            <div id="print-section">
            <table align="center" id=""> 
                <thead hidden>COMPROBANTE (COPIA TABSA)</thead>
                    <tbody >
                    <tr>
                        <th style="font-weight: bold; font-size: 14px; text-align: left;">Nº DE ORDEN:</th>
                        <td>{{bultos[0].id_cruce+bultos[0].id_encomienda}}</td>
                    </tr>
                    <!-- <tr>
                        <th style="font-weight: bold; font-size: 14px; text-align: left;">Nº DE CRUCE:</th>
                        <td>{{bultos[0].id_cruce}}</td>
                    </tr> -->
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <th style="font-weight: bold; font-size: 14px; text-align: left;">DESTINATARIO:</th>
                        <td>{{bultos[0].persona_recibe[0].nombre+' '+bultos[0].persona_recibe[0].apellido | uppercase}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">TELÉFONO:</th>
                        <td>{{bultos[0].persona_recibe[0].telefono}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">RESIDENTE:</th>
                        <td>{{bultos[0].persona_recibe[0].residente}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">UBICACIÓN:</th>
                        <td>{{bultos[0].destino | uppercase}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <th style="font-weight: bold; font-size: 14px; text-align: left;">REMITENTE:</th>
                        <td>{{bultos[0].persona_envia[0].nombre+' '+bultos[0].persona_envia[0].apellido | uppercase}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">CORREO:</th>
                        <td>{{bultos[0].persona_envia[0].email | uppercase}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">TELÉFONO:</th>
                        <td>{{bultos[0].persona_envia[0].telefono}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">RESIDENTE:</th>
                        <td>{{bultos[0].persona_envia[0].residente}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">UBICACIÓN:</th>
                        <td>{{bultos[0].origen | uppercase}}</td>
                    </tr>
                </tbody>
                <tr align="center">
                    <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                </tr>
                <tbody  *ngFor="let bulto of bultos">
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">CONTENIDO:</th>
                        <td>{{bulto.nombre | uppercase}}</td>
                    </tr>
                    <tr>
                        <th style="font-weight: bold; font-size: 14px; text-align: left;">PESO:</th>
                        <td>{{bulto.peso | uppercase}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">VOLUMEN:</th>
                        <td>{{bultos[0].volumen | uppercase}}</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">VALOR DECLARADO:</th>
                        <td>${{bultos[0].valor}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">FECHA ENTREGA:</th>
                        <td>{{bultos[0].fecha}}</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">ATENDIDO POR:</th>
                        <td>{{bultos[0].descripcion | uppercase}}</td>
                    </tr>
                    <tr align="center">
                        <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
                    </tr>
                    <tr>
                        <th style="font-size:14px; font-weight: bold; text-align: left;">RECIBIDO POR:</th>
                        <td style="font-size: 10px; opacity: 70%;">NOMBRE | RUT</td>
                    </tr>
                    <tr>
                        <td>FIRMA:</td>
                    </tr>
                    <tr>
                        <td style="color: white;">.</td>
                    </tr>
                    <tr align="center">
                        <td class="mt-5" style="font-size:13px; font-weight: bold;" colspan="5">___________________________</td>
                    </tr>
            </tbody>
        </table>
        </div>
        <div id="print" hidden>
        <table align="center"> 
            <thead>COMPROBANTE (COPIA CLIENTE)</thead>
            <tbody >
            <tr>
                <th style="font-weight: bold; font-size: 14px; text-align: left;">Nº DE ORDEN:</th>
                <td>{{bultos[0].id_cruce+bultos[0].id_encomienda}}</td>
            </tr>
            <tr>
                <th style="font-weight: bold; font-size: 14px; text-align: left;">Nº DE CRUCE:</th>
                <td>{{bultos[0].id_cruce}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
            </tr>
            <tr>
                <th style="font-weight: bold; font-size: 14px; text-align: left;">DESTINATARIO:</th>
                <td>{{bultos[0].persona_recibe[0].nombre+' '+bultos[0].persona_recibe[0].apellido | uppercase}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">CORREO:</th>
                <td>{{bultos[0].persona_recibe[0].email | uppercase}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">TELÉFONO:</th>
                <td>{{bultos[0].persona_recibe[0].telefono}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">RESIDENTE:</th>
                <td>{{bultos[0].persona_recibe[0].residente}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">UBICACIÓN:</th>
                <td>{{bultos[0].destino | uppercase}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
            </tr>
            <tr>
                <th style="font-weight: bold; font-size: 14px; text-align: left;">REMITENTE:</th>
                <td>{{bultos[0].persona_envia[0].nombre+' '+bultos[0].persona_envia[0].apellido | uppercase}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">TELÉFONO:</th>
                <td>{{bultos[0].persona_envia[0].telefono}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">RESIDENTE:</th>
                <td>{{bultos[0].persona_envia[0].residente}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">UBICACIÓN:</th>
                <td>{{bultos[0].origen | uppercase}}</td>
            </tr>
        </tbody>
        <tr align="center">
            <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
        </tr>
        <tbody  *ngFor="let bulto of bultos">
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">CONTENIDO:</th>
                <td>{{bulto.nombre | uppercase}}</td>
            </tr>
            <tr>
                <th style="font-weight: bold; font-size: 14px; text-align: left;">PESO:</th>
                <td>{{bulto.peso | uppercase}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">VOLUMEN:</th>
                <td>{{bultos[0].volumen | uppercase}}</td>
            </tr>
        </tbody>
        <tbody>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">VALOR DECLARADO:</th>
                <td>${{bultos[0].valor}}</td>
            </tr>
            <tr align="center">
                <td style="font-size:13px; font-weight: bold;" colspan="5">________________________________________________________</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">FECHA ENTREGA:</th>
                <td>{{bultos[0].fecha}}</td>
            </tr>
            <tr>
                <th style="font-size:14px; font-weight: bold; text-align: left;">ATENDIDO POR:</th>
                <td>{{bultos[0].descripcion | uppercase}}</td>
            </tr>
            <!-- <tr>
                <td>FIRMA:</td>
            </tr>
            <tr>
                <td style="color: white;">.</td>
            </tr>
            <tr align="center">
                <td class="mt-5" style="font-size:13px; font-weight: bold;" colspan="5">___________________________</td>
            </tr> -->
    </tbody>
</table>
    </div>
</body>
    </mat-card-content>
</mat-card>
</mat-dialog-content>