import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'franz';
  loading : boolean;

  constructor(){
    this.loading = false;
  }

  setLoading(load:boolean){
    this.loading = load;
    console.log('loading:' + this.loading);
  }
}
