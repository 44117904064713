import { Component, OnInit } from '@angular/core';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    selectedValue: string;
    constructor() {
        this.selectedValue = '';
    }

    ngOnInit(): void {
    }

}
