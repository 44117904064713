
<div class="contenedor">
    <button mat-raised-button (click)="volver(id_cruce)" style="margin-left: 10px;">
        <mat-icon>keyboard_backspace</mat-icon> Volver
    </button>

	<form
		name='formulario_carga'
		[formGroup]='formulario_carga'
        (keydown.enter)="$event.preventDefault()"
		(ngSubmit)='guardar_formulario()'>

	<!--<mat-card>Bienvenido {{ usuario }}</mat-card>-->
	<!--<mat-card>
		<h4>Datos tramo</h4>
		<mat-form-field appearance="fill">
		<mat-label>Tramo</mat-label>
		<mat-select style="font-size: 12px;"
            #nombreTramo
			formControlName='tramo'
			required>
			<mat-option (click)="setNombreTramo(tramo.nombre_tramo)" *ngFor="let tramo of tramos" [value]="tramo.id_tramo">{{ tramo.nombre_tramo }}</mat-option>
		</mat-select>
		</mat-form-field>
	</mat-card>-->



	<mat-card>
		<h4>Datos Destinatario</h4>
        <mat-label>Tipo de identificación</mat-label>
        <mat-radio-group formControlName="tipo_documento_destinatario" color="primary">
            
            <mat-radio-button value="1" [checked]="true" style="margin-left: 10px;">Rut</mat-radio-button>
            <mat-radio-button value="2" style="margin-left: 10px;">Otro</mat-radio-button>
        </mat-radio-group>
		<mat-form-field appearance="fill" *ngIf="formulario_carga.value.tipo_documento_destinatario != 2">
		<mat-label>RUT</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='rut_destinatario'
            (blur)="getPersonaByRut(rut_destinatario.value,0)"
            (keyup)="formatear_rut(2)"
            (keydown.enter)="getPersonaByRut(rut_destinatario.value,0)"
			#rut_destinatario required>
		</mat-form-field>

        <mat-form-field appearance="fill" *ngIf="formulario_carga.value.tipo_documento_destinatario == 2">
            <mat-label>Pasaporte/DNI</mat-label>
            <input matInput
			autocomplete="off"
			formControlName='pasaporte_destinatario'
            (blur)="getPersonaByPasaporte(formulario_carga.value.pasaporte_destinatario,0)"
            (keydown.enter)="getPersonaByPasaporte(formulario_carga.value.pasaporte_destinatario,0)"
			required>
		</mat-form-field>

		<mat-form-field appearance="fill">
		<mat-label>Nombre</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='nombre_destinatario' required>
		</mat-form-field>

		<mat-form-field appearance="fill">
		<mat-label>Apellido</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='apellido_destinatario' required>
		</mat-form-field>

		<mat-form-field appearance="fill">
		    <mat-label>Teléfono Destinatario</mat-label>
		    <input matInput autocomplete="off"
			formControlName='telefono_destinatario' required>
		</mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email Destinatario</mat-label>
            <input matInput autocomplete="off"
                formControlName='email_destinatario' required>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
            <mat-label>Residente</mat-label>
            <input matInput autocomplete="off"
                [disabled]="disable_resiente"
                formControlName='residente_destinatario' required>
        </mat-form-field> -->
        <div appearance="fill">
            <mat-label>Residente</mat-label>
            <p *ngIf="formulario_carga.value.residente_destinatario" [ngStyle]="{'color':formulario_carga.value.residente_destinatario == 'No' ? '#f44336' : ''}" style="font-weight:bolder; font-size: 28px"> {{formulario_carga.value.residente_destinatario | uppercase}}</p>
            <p *ngIf="!formulario_carga.value.residente_destinatario" style="color:#f44336; font-weight:bolder; font-size: 28px"> {{'No' | uppercase}}</p>
        </div>
	</mat-card>

    <mat-card>
        <br><br>
        <table>
            <thead>
                <th style="min-width: 2%; max-width: 4%;">#</th>
                <th style="min-width: 5%; max-width: 6%;">Peso</th>
                <th style="min-width: 5%; max-width: 6%;">Largo</th>
                <!-- <th width="6%">Volumen</th> -->
                <th style="min-width: 16%; max-width: 18%;">Contenido declarado</th>
                <!-- <th width="18%">Descripción</th> -->
                <th style="min-width: 5%; max-width: 6%;">Cantidad</th>
                <th style="min-width: 5%; max-width: 6%;">Quitar</th>
            </thead>
            <tbody formArrayName="bulto" align="center">
                <tr *ngFor="let control of fila.controls; let i=index" [formGroupName]="i">
                    <td>{{ i+1 }}</td>
                    <td>
                        <input
                            type='number'
                            min="0" step=".1"
                            autocomplete="off"
                            style="width: 40px;"
                            class="input_item"
                            formControlName='peso'>
                    </td>
                    <td>
                        <input
                            type='number'
                            min="0" step=".1"
                            autocomplete="off"
                            style="width: 40px;"
                            class="input_item"
                            formControlName='largo'>
                    </td>
                    <!-- <td>
                        <input
                            type='number'
                            min="0" step=".1"
                            autocomplete="off"
                            style="width: 50px;"
                            class="input_item"
                            formControlName='volumen'>
                    </td> -->
                    <td>
                        <input type='text'
                            autocomplete='off'
                            placeholder='Ítem'
                            matInput
                            class='input_item'
                            formControlName='id_item'>
                        <!-- <input type='text'
                            autocomplete='off'
                            placeholder='Ítem'
                            matInput
                            class='input_item'
                            formControlName='id_item'
                            (keypress)="buscar_item($event, i)"
                            [matAutocomplete]="auto"> -->
                        <!--<input type='text' size='2' formControlName='descripcion'>-->
                        <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(items)">
                            <mat-option *ngFor="let option of filteredOptions | async"
                                        [value]="option.id_item">
                                {{ option.nombre }}
                            </mat-option>
                        </mat-autocomplete> -->
                    </td>
                    <td hidden>
                        <input type='text'
                            autocomplete='off'
                            placeholder='Descripción'
                            matInput
                            class='input_item'
                            formControlName='descripcion'>
                    </td>
                    <td>
                        <input
                            type='number'
                            min="1" step="1"
                            autocomplete="off"
                            style="width: 40px;"
                            class="input_item"
                            formControlName='cantidad'>
                    </td>
                    
                    <td>
                        <button
                            mat-mini-fab
                            color="warn"
                            (click)="borrar_linea(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table><br>
        <button mat-stroked-button type='button' (click)="agregar_fila()">Agregar ítem</button>
    </mat-card>
    <mat-card>
        <div style="text-align: left;" class="container">
            <mat-form-field class="new" appearance="fill" >

                <mat-label>Volumen</mat-label>

                <input
                matInput
                type='number'value
                min="0" step=".1"
                autocomplete="off"
                class="input_volumen"
                width="50px"
                formControlName='volumen'>
            </mat-form-field>

            <mat-form-field class="new" appearance="fill" >
                <mat-label>Nº de Lote</mat-label>

                <input
                matInput
                type='number'value
                min="1" step="1"
                autocomplete="off"
                width="50px"
                class="input_numero_lote"
                formControlName='numero_lote'>
            </mat-form-field>

            <mat-form-field class="new" appearance="fill" >
                <mat-label>Valor (CLP)</mat-label>
                <input
                matInput
                type='number'
                min="0" step="1"
                autocomplete="off"
                class="input_valor"
                width="50px"
                formControlName='valor'>
            </mat-form-field>

            <mat-form-field class="new" appearance="fill" >
                <mat-label>Usuario</mat-label>
                <input
                matInput
                type='text'
                autocomplete="off"
                class="input_usuario"
                width="50px"
                formControlName='usuario_desc'>
            </mat-form-field>
        </div>
    </mat-card>
    <mat-card>
		<h4>Datos Remitente</h4>
        <mat-label>Tipo de identificación</mat-label>
        <mat-radio-group formControlName="tipo_documento_cargador" color="primary">
            
            <mat-radio-button value="1" [checked]="true" style="margin-left: 10px;">Rut</mat-radio-button>
            <mat-radio-button value="2" style="margin-left: 10px;">Otro</mat-radio-button>
        </mat-radio-group>
		<mat-form-field appearance="fill" *ngIf="formulario_carga.value.tipo_documento_cargador!=2">
		<mat-label>RUT</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='rut_cargador'
            (keypress)="omit_special_char($event)"
            (blur)="getPersonaByRut(rut_cargador.value,1)"
            (keydown.enter)="getPersonaByRut(rut_cargador.value,1)"
            (keyup)="formatear_rut(1)"
			#rut_cargador required>
		</mat-form-field>

        <mat-form-field appearance="fill" *ngIf="formulario_carga.value.tipo_documento_cargador == 2">
            <mat-label>Pasaporte/DNI</mat-label>
            <input matInput
			autocomplete="off"
			formControlName='pasaporte_cargador'
            (blur)="getPersonaByPasaporte(formulario_carga.value.pasaporte_cargador,1)"
            (keydown.enter)="getPersonaByPasaporte(formulario_carga.value.pasaporte_cargador,1)"
			required>
		</mat-form-field>

		<mat-form-field appearance="fill">
		<mat-label>Nombre</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='nombre_cargador' required>
		</mat-form-field>

		<mat-form-field appearance="fill">
		<mat-label>Apellido</mat-label>
		<input matInput
			autocomplete="off"
			formControlName='apellido_cargador' required>
		</mat-form-field>


		<mat-form-field appearance="fill">
		<mat-label>Teléfono Remitente</mat-label>
		<input matInput autocomplete="off"
			formControlName='telefono_cargador' required>
		</mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email Remitente</mat-label>
            <input matInput autocomplete="off"
                formControlName='email_cargador' required>
        </mat-form-field>
<!-- 
        <mat-form-field appearance="fill">
            <mat-label>Residente</mat-label>
            <input matInput autocomplete="off"
                (disabled)="disable_resiente"
                formControlName='residente_cargador' required>
        </mat-form-field> -->
        <div appearance="fill">
        <mat-label>Residente</mat-label>
        <p *ngIf="formulario_carga.value.residente_cargador" [ngStyle]="{'color':formulario_carga.value.residente_cargador == 'No' ? '#f44336' : ''}" style="font-weight:bolder; font-size: 28px">{{formulario_carga.value.residente_cargador | uppercase}}</p>
        <p *ngIf="!formulario_carga.value.residente_cargador" style="color:#f44336; font-weight:bolder; font-size: 28px">{{'No' | uppercase}}</p>
        </div>
    </mat-card>
    <!--<mat-card>
        <button mat-stroked-button type='button' (click)="openDialog()">Tabla</button>
    </mat-card>-->

    <mat-card>
        <button mat-stroked-button [disabled]="validar(formulario_carga.value)">Guardar</button>
    </mat-card>

	</form>
</div>

