import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-resumen',
    templateUrl: './resumen.component.html',
    styleUrls: ['./resumen.component.css']
})
export class ResumenComponent implements OnInit {

    data: any;

    constructor(private api: ApiService) {
        this.data = JSON.parse(this.api.leerCarga() || '{}');
        console.log(JSON.stringify(this.data));
    }

    ngOnInit(): void {

    }

}
