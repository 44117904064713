import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/services/api.service';
import { Ibulto } from 'src/app/models/bulto.interface';
import { BultoImpresionComponent } from '../../bulto-impresion/bulto-impresion.component';
import { CambiarCruceComponent } from '../cambiar-cruce/cambiar-cruce.component';

@Component({
  selector: 'app-bultos-estado',
  templateUrl: './bultos-estado.component.html',
  styleUrls: ['./bultos-estado.component.css']
})
export class BultosEstadoComponent implements OnInit {

  sbultos: number[] = [];
  id_cruce: any;
  estado: any;
  data: any;
  usuariotest: boolean = false
  test: boolean = true;
  bultos! : Ibulto[]

  columnas: string[] = ['id', 'id encomienda', 'item', 'lote','ticket', 'fecha registro', 'ingresado por', 'cambiar estado', 'cambiar cruce'];

  constructor(private router: Router,
    private api: ApiService,
    public dialog: MatDialog,
    private app: AppComponent,
    private route: ActivatedRoute
    ) { }
  usuario = sessionStorage.getItem('usuario') == 'bramirez' || sessionStorage.getItem('usuario') == 'naguilar' ? true : false


  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.id_cruce = params['id_cruce'];
      this.estado = params['estado'];
    })

    this.api.bultosEstado(this.id_cruce, this.estado).subscribe( data => {
      this.bultos = data
      console.log('data estado bultos')
      console.log(data)
    })
  }

  impresion(bultos: any, id_bulto: number): void {
    console.log(id_bulto);
    const dialogRef = this.dialog.open(BultoImpresionComponent,{
        width: '550px',
        data: {bultos: bultos, id_bulto: id_bulto}
    });
    dialogRef.afterClosed().subscribe( resp => {
        console.log(resp);
    });
  }

  cambiarEstado(id_qr:any){
    this.api.cambiarEstado(id_qr).subscribe( data => {
      console.log('data nuevo estado')
      console.log(data)
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl],{state: { id_cruce: this.id_cruce }});
          console.log(currentUrl);
      });
    })
  }
  volver(id_cruce: number){
    this.router.navigate(['/cargas/cruces/lista_envios'],{state: { id_cruce: id_cruce }});
  }

  selectedBultos(id_encomienda:any){
    const index = this.sbultos.indexOf(id_encomienda);

    if (index === -1) {
      // Si el id_encomienda no está en sbultos, lo agregamos
      this.sbultos.push(id_encomienda);
    } else {
      // Si el id_encomienda ya está en sbultos, lo quitamos
      this.sbultos.splice(index, 1);
    }

    console.log("id's seleccionadas")
    console.log(this.sbultos)
  }

  cambiarCruce(){
    const dialogRef = this.dialog.open(CambiarCruceComponent,{
        width: '550px',
        data: {id_encomienda: this.sbultos}
    });
    dialogRef.afterClosed().subscribe( resp => {
        console.log(resp);
    });
  }
}
