import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TicketI } from 'src/app/models/ticket.interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-plantilla-impresion',
  templateUrl: './plantilla-impresion.component.html',
  styleUrls: ['./plantilla-impresion.component.css']
})
export class PlantillaImpresionComponent implements OnInit {

  data : any;
  id_cruce: number;
  id_bulto! : number;
  bultos! : TicketI[];
  prueba! : any[];

  constructor( private route: Router, private api: ApiService) { 
    this.data = this.route.getCurrentNavigation()?.extras.state?.data;
    this.id_bulto = this.data.bultos
          this.id_cruce = this.route.getCurrentNavigation()?.extras.state?.id_cruce;
          this.api.getTicket(this.id_bulto).subscribe(
            data => {
              this.bultos = data;
              console.log(this.bultos)
              }
            );
  }

  ngOnInit(): void {
  }

}
